import { routeActions } from 'react-router-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { getStore } from '../store/all-store';
import config from '../constants/config-constants';
import routes, { blockedRoles, hotlineRules } from '../constants/routes-constants';
import { isAuthenticated } from '../api/ApiCaller';
import { enhanceUserInfo } from '../api/data-enhancer';
import {
  addFlashMessage,
  afterPaybackRedirectFinish,
  afterPaybackRedirectStart,
  bookingViewGetInvoices,
  checkResetPasswordTokenRequest,
  clearItalianInvoiceStatus,
  closeHeaderProfileMenu,
  companyClearPaybackSettingsData,
  companyGetPaybackSettingsAction,
  companySavePaybackRedirectData,
  exposeLoginIfStored,
  getAllApplicationsListIfNeeded,
  getAllConfigurationList,
  getAllSubCompaniesList,
  getAllVoucherGroups,
  getAppBrands,
  getAutolibCardsList,
  getBackUserDetail,
  getBankHolidays,
  getBookingFindAvailableVehicle,
  getBookingFindMemberAssociatedSites,
  getBookingLicenseImage,
  getBookingsCountByVoucherUses,
  getBookingsCustomFields,
  getBookingsList,
  getBookingTransactions,
  getBrandsList,
  getCategoriesRequest,
  getCleanlinessProblems,
  getColors,
  getScheduledExports,
  getCompaniesListIfNeeded,
  getCompaniesListWithSearch,
  getCompaniesTechnicianList,
  getCompany,
  getCompanyCurrentContract,
  getCompanyCustomFields,
  getCompanyDetail,
  getCompanyEmailSetup,
  getConfigurationDetail,
  getConfigurationList,
  getCurrencies,
  getCustomFieldsMember,
  getDataVehiclePlanning,
  getDelayedBookings,
  getDmsInvoiceErrors,
  getExpediteMembers,
  getFailedBookings,
  getFailedRrsBookings,
  getFeedbackDetail,
  getFeedbackReportImage,
  getFuelCardsList,
  getHeaderSubCompaniesList,
  getHotlineDetail,
  getHotlines,
  getInsuranceContractsList,
  getInversFleets,
  getInvoiceParams,
  getSingleInvoice,
  getInvoicesList,
  getInvoiceVatRateParam,
  getItalianInvoiceStatuses,
  getLastBookings,
  getLeaseContractsList,
  getlowAccessoryBatVehicles,
  getMemberComments,
  getMemberDetail,
  getMemberDrivingLicenseImage,
  getMemberEmployerCertificateImage,
  getMemberIdentityDocumentImage,
  getMembersList,
  getMemberTypesList,
  getModelsList,
  getNonValidatedMembers,
  getParkingDetails,
  getParkingsList,
  getSingleBooking,
  getSingleBrand,
  getSingleCategory,
  getSingleColor,
  getSingleModel,
  getSingleVersion,
  getSiteDetails,
  getSitesList,
  getSitesListFromSubCompany,
  getStatuses,
  getSubCompaniesList,
  getSubCompanyDetails,
  getSubCompanyEmailSetup,
  getSubCompanyInvoiceParams,
  getSubCompanyInvoiceVatRates,
  getSubscriptionCustomFields,
  getUnmanagedDamages,
  getVehicleCategoriesList,
  getVehicleCompany,
  getVehicleDamageDetail,
  getVehicleDamagesList,
  getVehicleDetail,
  getVersionsList,
  getVoucherGroupDetail,
  getVouchers,
  initCreateVehicleForm,
  loaderOnRouteLoad,
  openSideSubMenu,
  requestHistoryEventsBooking,
  requestTokenRefresh,
  resetSideMenu,
  saveRedirectLocation,
  setBookingEdtionMode,
  setBookingFindMemberCurrentSortedIndex,
  setBookingFindMemberSortIsDescending,
  setBookingSelectedMember,
  setBookingsFilters,
  setCurrentCompany,
  setCurrentSuperCompanyInHeader,
  setCurrentTabIndex,
  setInvoiceFindBookingCurrentSortedIndex,
  setInvoiceFindBookingSortIsDescending,
  setInvoicesFilters,
  setParentSuperCompanyInHeader,
  setSubCompaniesTabIndex,
  setSubCompanySelected,
  setToken,
  setVehicleDamagesCurrentSortedIndex,
  setVehicleDamagesSortIsDescending,
  setVoucherGroupsFilters,
  storeParams,
  storeResetPasswordToken,
  subCompanyClearPaybackSettingsData,
  subCompanyGetPaybackSettingsAction,
  subCompanySavePaybackRedirectData,
  updateCurrentRouteGroup,
  updateCurrentRouteName,
  searchBookings,
  getNonOperatingVehicles,
  dashboardResetItemsCount,
  getBookingDelayedStatus,
  setScheduledExportCurrentId,
  getMemberProfileHistory,
  getConnectedAccount,
  getSubCompanyConnectedAccount,
  getLowFuelLevelVehicles,
  getProplannerErrors,
  getRegistrationFileInfo
} from '../actions/all-actions';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  EXTERNAL_INVOICE,
  PAYMENT_PROVIDERS,
  SORT_PROPERTY_NAME,
  STATUS_APPROVED,
  STATUS_CANCELLED,
  WORLDPAY_STATUS_SUCCESS
} from '../constants/backend-constants';
import {
  ALL,
  COMPANY_ALL,
  COMPANY_TYPE_SUB,
  COMPANY_TYPE_SUPER,
  delayedBookingStatuses,
  FLASH_MESSAGE_TYPE_ERROR,
  FLASH_MESSAGE_TYPE_INFO,
  STORAGE_KEY
} from '../constants/generic-constants';
import {
  clearQueryParams,
  fallbackFunc,
  formPost,
  getBankHolidaysDate,
  getQueryParams,
  getUriObj,
  hideDriverImagesForCustomCompanyId,
  isLocalEnv,
  isValidId,
  jsonParseSafe,
  safe,
  scrollToTop,
  shortenBookingId
} from '../utils/utils';
import { apiParams } from '../constants/api-params-constants';
import { getVehicleStatusesList } from '../actions/vehicleStatuses-actions';
import { getVouchersCompanies, getVouchersCompanyParkings } from '../containers/Voucher/Voucher.actions';

import {
  backUserPageRoleRules,
  checkRole,
  subCompanyPaybackBankDetailsEditRules,
  subCompanyPaybackConfigTabRules,
  subCompanyPaybackSettingsEditRules,
  superCompanyPaybackBankDetailsEditRules,
  superCompanyPaybackConfigTabRules,
  superCompanyPaybackSettingsEditRules
} from '../constants/backuser-role-rules';

import {
  bookingsCurrentSizeSelector,
  createNameToIdMap,
  currentCompanyIdSelector,
  headerCompanyListSelector,
  userHaveOnlyOneSuperCompanySelector,
  userRoleSelector,
  validCompanyIdSelector,
  validSubCompanyIdSelector,
  vehicleBrandsLoadingSelector,
  vehicleBrandsSelector,
  currentCompanySelector,
  vehicleModelsSelector
} from '../selectors/all-selectors';

import { storeLegacySubCompanyBankDetails } from '../actions/subCompanies-actions';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { getHtmlMsg, getMsg } from '../utils/IntlGlobalProvider';
import { regexShortId } from '../constants/regex';
import { safeRouteChange } from './utils-routing';

function hideErrors(callback) {
  return [callback, callback];
}

/** Uncomment to automatically preselected super company and company */
function vpDevCompanySelect() {
  return dispatch => {
    // dispatch(preselectSuperCompany(16, 1)); // valid => RCI mobility
    // dispatch(preselectSuperCompany(16, 0)); // valid => 9 Renault Brazil
    // dispatch(preselectSuperCompany(18, 4)); // valid => AirStone => testingCompany
    // dispatch(preselectSuperCompany(6, 0)); // valid => Adrien corp
    // dispatch(preselectSuperCompany(3, 0)); // valid => RCIM
    // dispatch(preselectSuperCompany(1, 5)); // develop => rci mobility super company - rci mobility
    // dispatch(preselectSuperCompany(6, 2)); // staging => AAirStone -> testingCompany
    // dispatch(preselectSuperCompany(79, 0)); // prod => garda uno -> testingCompany
  };
}

function getVpData(superCompanyId, subCompanyId) {
  return dispatch => {
    dispatch(getSitesListFromSubCompany(subCompanyId));
    dispatch(getCompanyCustomFields(superCompanyId));
    dispatch(getDataVehiclePlanning());
    dispatch(getBankHolidays(getBankHolidaysDate()));
    dispatch(getMemberTypesList());
  };
}

/** This function is used for development purposes  **/
function preselectSuperCompany(superCompanyIndex = 0, subCompanyIndex = 0) {
  return (dispatch, getState) => {
    if (!config.devMode) return;

    const superCompany = getState().companies.headerList[superCompanyIndex];
    dispatch(setCurrentCompany(superCompany));

    return dispatch(getHeaderSubCompaniesList()).then(data => {
      const subCompany = data[subCompanyIndex];
      const superCompanyId = superCompany.id;
      const subCompanyId = subCompany && subCompany.id;

      dispatch(setSubCompanySelected(subCompany));
      dispatch(getVpData(superCompanyId, subCompanyId));
    });
  };
}

function handlePaybackRedirect(store, data) {
  const { params, location } = data;
  const { splat } = params || {};
  const pageName = 'paybackRedirect';
  const isPaybackPage = splat === pageName;

  if (isPaybackPage) {
    store.dispatch(afterPaybackRedirectStart());

    const { query, search } = location || {};
    const { status } = query || {};
    const { customerRef, targetCurrency, ...rest } = query || {};
    const companyId = (customerRef || '').toLowerCase();
    let bankAccountDetails = JSON.stringify({ ...rest, customerRef: companyId, currency: targetCurrency });
    const paybackData = jsonParseSafe(localStorage.getItem(STORAGE_KEY.PAYBACK_DATA));
    const origin = localStorage.getItem(STORAGE_KEY.PAYBACK_SAVE);
    const { values, currentTabIndex, type, legacyPayback } = paybackData || {};

    if (origin) {
      if (origin === window.location.origin) {
        localStorage.removeItem(STORAGE_KEY.PAYBACK_SAVE);
      } else {
        store.dispatch(afterPaybackRedirectFinish());
        localStorage.removeItem(STORAGE_KEY.PAYBACK_SAVE);
        window.location.href = origin + '/#/' + pageName + search;
        return true;
      }
    }

    localStorage.removeItem(STORAGE_KEY.PAYBACK_DATA);

    function showMessage(type, delay) {
      let contentKey = 'common_unknown';

      if (status) contentKey = 'subCompanies_paymentDetails_' + status.toLowerCase();
      else if (type === FLASH_MESSAGE_TYPE_ERROR) contentKey = 'error_server_unknown';

      store.dispatch(
        addFlashMessage({
          contentKey,
          type,
          delayToNextRoute: !!delay,
          numberOfRoutes: delay ? 2 : 1
        })
      );
    }

    if (status !== WORLDPAY_STATUS_SUCCESS) {
      bankAccountDetails = null;

      const type = status === STATUS_CANCELLED ? FLASH_MESSAGE_TYPE_INFO : FLASH_MESSAGE_TYPE_ERROR;
      showMessage(type, status === FLASH_MESSAGE_TYPE_ERROR);
    }

    if (legacyPayback) {
      handleLegacyPayback(store, query, companyId);
      return true;
    } else if (type === COMPANY_TYPE_SUPER) {
      store.dispatch(
        companySavePaybackRedirectData({
          values,
          currentTabIndex,
          bankAccountDetails
        })
      );

      const companyPath = routes.companyDetail.path.replace(':companyId', companyId);
      store.dispatch(routeActions.push(companyPath));

      return true;
    } else if (type === COMPANY_TYPE_SUB) {
      store.dispatch(
        subCompanySavePaybackRedirectData({
          values,
          currentTabIndex,
          bankAccountDetails
        })
      );

      const subCompanyPath = routes.subCompanyDetails.path.replace(':subCompanyId', companyId);
      store.dispatch(routeActions.push(subCompanyPath));

      return true;
    } else {
      // handle no data case
      store.dispatch(afterPaybackRedirectFinish());
      store.dispatch(routeActions.push(routes.dashboard));

      return true;
    }
  } else return false;
}

function handleLegacyPayback(store, query, companyId) {
  const { status } = query || {};
  const storedToken = window.localStorage.getItem('token');

  store.dispatch(afterPaybackRedirectFinish());

  function saveTokenAndGetUserInfo(work) {
    if (storedToken) {
      store.dispatch(setToken(storedToken));
      store
        .dispatch(requestTokenRefresh())
        .then(work)
        .catch(goToSubCompany);
    } else {
      // no token stored, attemt to go to dashboard
      store.dispatch(routeActions.push(routes.dashboard));
    }
  }

  saveTokenAndGetUserInfo(work);

  function goToSubCompany() {
    if (companyId) {
      const subCompanyPath = routes.subCompanyDetails.path.replace(':subCompanyId', companyId);
      store.dispatch(routeActions.push(subCompanyPath));
    } else {
      // no company id, redirect to dashboard
      store.dispatch(routeActions.push(routes.dashboard));
    }
  }

  function work() {
    if (WORLDPAY_STATUS_SUCCESS === status) {
      store.dispatch(storeLegacySubCompanyBankDetails(query)).then(goToSubCompany);
    } else {
      goToSubCompany();
    }
  }
}

function handlePaybackSave(store, data) {
  const { params, location } = data;
  const { splat } = params || {};
  const pageName = 'paybackSave';
  const isPaybackSave = splat === pageName;

  if (isPaybackSave) {
    const { query } = location || {};
    const { origin, url, ...params } = query || {};
    localStorage.setItem(STORAGE_KEY.PAYBACK_SAVE, origin);
    formPost(url, params);
    return true;
  }
  return false;
}

function redirectToLogin() {
  getStore().dispatch(saveRedirectLocation());
  safeRouteChange(config.loginRoute);
}

export function onEnterDefault() {
  return () => getStore().dispatch(loaderOnRouteLoad());
}

export function onEnterNoRoute() {
  return data => {
    const store = getStore();

    if (handlePaybackSave(store, data) || handlePaybackRedirect(store, data)) {
      store.dispatch(loaderOnRouteLoad());
      return;
    }

    // default route
    setTimeout(function() {
      // react-router will render a blank page if we don't go to the next event loop before redirecting
      redirectToLogin();
    }, 0);
  };
}

function checkRoleRights(state, routeName) {
  const userRole = userRoleSelector(state);
  const { rules } = routes[routeName] || {};

  let canProceed = userRole && rules ? checkRole(rules, userRole) : true;

  if (userRole && !checkRole(blockedRoles, userRole)) {
    canProceed = false;
    addErrorMsg(getHtmlMsg('backUser_role_not_authorized', { values: { role: getMsg('backUsers_role_' + userRole) } }));
  }

  if (canProceed && routeName === 'editBackUser') {
    const { detailBackUser } = state.backUsers;
    const { role: backUserRole } = detailBackUser || {};
    canProceed = checkRole(backUserPageRoleRules[userRole], backUserRole);
  }

  return canProceed;
}

export function onEnterRoute(routeName) {
  return function(nextState, replaceState, callback) {
    const store = getStore();
    const state = store.getState();
    const { needsAuthentication, group } = routes[routeName] || {};

    const roleAuthentificated = checkRoleRights(state, routeName);
    const userAuthentificated = isAuthenticated();

    // autologin from localstorage
    if (!userAuthentificated && needsAuthentication) {
      let storedToken = window.localStorage.getItem('token');
      const queryToken = getQueryParams().token;

      if (queryToken) {
        if (!storedToken) storedToken = queryToken;
        clearQueryParams();
      }

      if (storedToken) {
        store.dispatch(setToken(storedToken));

        // token refresh will hydrate the  userInfo object
        store
          .dispatch(requestTokenRefresh())
          .then(() => {
            store.dispatch(routeActions.replace(window.location.hash.slice(1)));
          })
          .catch(redirectToLogin);
        return;
      }
    }

    if ((!userAuthentificated && needsAuthentication) || !roleAuthentificated) {
      if (routeName === config.loginRoute) {
        console.error('loginRoute cannot require authentication!');
      } else {
        redirectToLogin();
        return;
      }
    }

    const afterAsync = () => {
      store.dispatch(updateCurrentRouteName(routeName));
      store.dispatch(updateCurrentRouteGroup(group));
      store.dispatch(loaderOnRouteLoad());
    };

    const showPage = () => {
      afterAsync();
      callback();
    };

    function handleRouteHook() {
      if (routeHooks.hasOwnProperty(routeName)) {
        routeHooks[routeName].call(null, { store, nextState, replaceState, callback: showPage });
      } else {
        showPage();
      }
    }

    store.dispatch(closeHeaderProfileMenu());

    // get header company/companies list

    const currentCompany = _get(state.companies, 'currentCompany');
    const currentCompanyIsAll = currentCompany === ALL || (currentCompany && currentCompany.id === ALL);
    const userCompanies = _get(state, 'user.userInfo.companies') || [];
    const firstCompanyDetails = userCompanies[0] || {};
    const firstCompany = { id: firstCompanyDetails.id, name: firstCompanyDetails.name };
    const goodRoute = routeName !== 'redirect';
    const companySelected = currentCompany && !currentCompanyIsAll;

    function handleSuperUsers() {
      store.dispatch(setCurrentCompany(COMPANY_ALL));
      store.dispatch(setSubCompanySelected(COMPANY_ALL));

      function findCompany(storageKey, data) {
        const storedId = window.localStorage.getItem(storageKey);

        if (data && isValidId(storedId)) {
          return data.find(company => company.id === storedId);
        }
      }

      store.dispatch(getCompaniesListIfNeeded()).then(data => {
        const foundCompany = findCompany(STORAGE_KEY.HEADER_COMPANY_ID, data);

        if (foundCompany) {
          store.dispatch(setCurrentCompany(foundCompany));
          store.dispatch(getHeaderSubCompaniesList(foundCompany.id)).then(data => {
            const foundSubCompany = findCompany(STORAGE_KEY.HEADER_SUB_COMPANY_ID, data);

            if (foundSubCompany) {
              store.dispatch(setSubCompanySelected(foundSubCompany));
            }
            handleRouteHook();
          });
        } else {
          handleRouteHook();
        }
      });
    }

    function setFirstSuperCompany() {
      store.dispatch(getCompaniesListIfNeeded()).then(() => {
        store.dispatch(setCurrentCompany(firstCompany));
        store.dispatch(getHeaderSubCompaniesList(firstCompany.id));
        handleRouteHook();
      });
    }

    if (userAuthentificated && goodRoute) {
      if (!companySelected) {
        if (userHaveOnlyOneSuperCompanySelector(state)) {
          return setFirstSuperCompany();
        } else {
          return handleSuperUsers();
        }
      }
    }

    handleRouteHook();
  };
}

const routeHooks = {
  redirect({ store, nextState, callback }) {
    callback();
    store.dispatch(routeActions.replace(nextState.params.path));
  },

  login({ store, callback }) {
    store.dispatch(exposeLoginIfStored());
    callback();
  },

  fleet({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(openSideSubMenu('fleet'));
    callback();
  },

  parkings({ store, callback }) {
    store.dispatch(openSideSubMenu('fleet'));
    callback();
  },

  addVehicle({ store, callback }) {
    const state = store.getState();
    const companyId = validCompanyIdSelector(state);
    const subCompanyId = validSubCompanyIdSelector(state);

    if (companyId) {
      if (subCompanyId) store.dispatch(getSitesListFromSubCompany(subCompanyId));
      else store.dispatch(getSitesList(companyId));
    }

    store.dispatch(openSideSubMenu('fleet'));
    store.dispatch(initCreateVehicleForm());

    callback();
  },

  vehicleCategories({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));
    callback();
  },

  addMember({ store, callback }) {
    const state = store.getState();
    const companyId = validCompanyIdSelector(state);

    if (companyId) {
      store.dispatch(getSubscriptionCustomFields(companyId));
    }

    callback();
  },

  categoriesList({ store, callback }) {
    return store.dispatch(getCategoriesRequest()).then(...hideErrors(callback));
  },

  vehicleEditCategory({ store, nextState, callback }) {
    const { categoryId } = nextState.params || {};
    return store.dispatch(getSingleCategory(categoryId)).then(...hideErrors(callback));
  },

  vehicleColors({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));
    callback();
  },

  colorsList({ store, callback }) {
    return store.dispatch(getColors()).then(...hideErrors(callback));
  },

  vehicleEditColor({ store, nextState, callback }) {
    const { colorId } = nextState.params || {};
    return store.dispatch(getSingleColor(colorId)).then(...hideErrors(callback));
  },

  scheduledExport({ store, callback }) {
    store.dispatch(openSideSubMenu('reports'));
    store
      .dispatch(getBrandsList())
      .then(callback)
      .catch(callback);
  },

  scheduledExportList({ store, callback }) {
    return store.dispatch(getScheduledExports()).then(callback);
  },

  scheduledExportViewConfig({ store, nextState, callback }) {
    const { exportId } = nextState.params || {};
    store.dispatch(setScheduledExportCurrentId(exportId));
    callback();
  },

  vouchersV2({ store, callback }) {
    store.dispatch(resetSideMenu());
    callback();
  },

  voucherGroups({ store, callback, nextState }) {
    const params = safe(() => getUriObj(nextState.params.groupsFilters));

    store.dispatch(setVoucherGroupsFilters(params));
    store.dispatch(getAllVoucherGroups({ params })).then(callback, callback);
  },

  vouchersAddGroup(props) {
    routeHooks.addVoucherGroup(props);
  },

  vouchersEditGroup(props) {
    routeHooks.voucherGroupDetail(props);
  },

  organization({ callback, store }) {
    store.dispatch(resetSideMenu());
    callback();
  },

  organizationBrands({ store, callback }) {
    return store.dispatch(getAppBrands()).then(...hideErrors(callback));
  },

  organizationSuperCompanies({ store, callback, nextState }) {
    const { brandId } = nextState.params || {};
    const params = { ...apiParams.companiesList };

    if (isValidId(brandId)) {
      params.brandId = brandId;
    }

    return store.dispatch(getCompaniesListWithSearch(params, true)).then(callback);
  },

  organizationAddSuperCompany(props) {
    safe(() => routeHooks.addCompany({ ...props, isModal: true }));
  },

  organizationEditSuperCompany(props) {
    safe(() => routeHooks.companyDetail({ ...props, isModal: true }));
  },

  organizationSubCompanies({ store, callback, nextState }) {
    const { companyId } = nextState.params || {};
    return store.dispatch(getAllSubCompaniesList([companyId])).then(...hideErrors(callback));
  },

  organizationAddSubCompany(props) {
    safe(() => routeHooks.addSubCompany({ ...props, isModal: true }));
  },

  organizationEditSubCompany(props) {
    safe(() => routeHooks.subCompanyDetails({ ...props, isModal: true }));
  },

  organizationSites({ store, callback, nextState }) {
    const { subCompanyId } = nextState.params || {};
    return store.dispatch(getSitesListFromSubCompany(subCompanyId)).then(...hideErrors(callback));
  },

  organizationAddSite(props) {
    safe(() => props.store.dispatch(getCompanyDetail(props.nextState.params.companyId)));
    safe(() => routeHooks.addSite({ ...props, isModal: true }));
  },

  organizationCopydSite(props) {
    safe(() => props.store.dispatch(getCompanyDetail(props.nextState.params.companyId)));
    safe(() => routeHooks.addSite({ ...props, isModal: true }));
  },

  organizationEditSite(props) {
    safe(() => routeHooks.siteDetails({ ...props, isModal: true }));
  },

  organizationParkings({ store, callback, nextState }) {
    const { siteId } = nextState.params || {};
    return store.dispatch(getParkingsList(siteId, true)).then(...hideErrors(callback));
  },

  organizationAddParking(props) {
    safe(() => routeHooks.addParking({ ...props, isModal: true }));
  },

  organizationEditParking(props) {
    safe(() => routeHooks.parkingDetails({ ...props, isModal: true }));
  },

  vehicleBrands({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));
    callback();
  },

  brandsList({ store, callback }) {
    return store.dispatch(getBrandsList()).then(...hideErrors(callback));
  },

  vehicleModels({ store, nextState, callback }) {
    const { brandId } = nextState.params || {};
    return store.dispatch(getModelsList(brandId)).then(...hideErrors(callback));
  },

  vehicleVersions({ store, nextState, callback }) {
    const { modelId } = nextState.params || {};
    return store.dispatch(getVersionsList(modelId)).then(...hideErrors(callback));
  },

  vehicleEditBrand({ store, nextState, callback }) {
    const { brandId } = nextState.params || {};
    return store.dispatch(getSingleBrand(brandId)).then(...hideErrors(callback));
  },

  vehicleEditModel({ store, nextState, callback }) {
    const { modelId } = nextState.params || {};
    return store.dispatch(getSingleModel(modelId)).then(...hideErrors(callback));
  },

  vehicleAddModel({ store, nextState, callback }) {
    const { brandId } = nextState.params || {};
    return store.dispatch(getSingleBrand(brandId)).then(...hideErrors(callback));
  },

  vehicleAddVersion({ store, nextState, callback }) {
    const { modelId } = nextState.params || {};
    return store.dispatch(getSingleModel(modelId)).then(...hideErrors(callback));
  },

  vehicleEditVersion({ store, nextState, callback }) {
    const { versionId } = nextState.params || {};
    return store.dispatch(getSingleVersion(versionId)).then(...hideErrors(callback));
  },

  backUsers({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(openSideSubMenu('members'));
    callback();
  },

  memberTypes({ store, callback }) {
    store.dispatch(openSideSubMenu('accountManagement'));
    callback();
  },

  addBackUser({ store, callback }) {
    store.dispatch(openSideSubMenu('members'));

    callback();
  },

  members({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(openSideSubMenu('members'));
    callback();
  },

  smartcards({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(openSideSubMenu('members'));
    callback();
  },

  uploadMember({ callback, store }) {
    store.dispatch(openSideSubMenu('members'));
    callback();
  },

  smartcardsDetail({ store, nextState, callback }) {
    const {
      params: { id }
    } = nextState;

    if (!id) {
      throw 'No smartcard ID provided.';
    }

    store.dispatch(openSideSubMenu('members'));
    callback();
  },

  smartcardsEvents({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(openSideSubMenu('members'));
    callback();
  },

  editBooking({ store, nextState, callback }) {
    const {
      params: { bookingId }
    } = nextState;

    store.dispatch(setBookingEdtionMode());

    return store.dispatch(getSingleBooking(bookingId)).then(
      booking => {
        store.dispatch(getBookingFindMemberAssociatedSites(booking.member.company.id)).then(data => {
          let member = Object.assign({}, booking.member);
          member.company.sites = data;
          store.dispatch(getCustomFieldsMember(member.id));
          store.dispatch(setBookingSelectedMember(enhanceUserInfo(member)));
          callback();
        });
      },
      err => {
        callback(err);
      }
    );
  },

  bookingFindMember({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
      params.markedForAnonymization = false;
      if (params.sort) {
        store.dispatch(setBookingFindMemberCurrentSortedIndex(params.sort.property));
        store.dispatch(setBookingFindMemberSortIsDescending(params.sort.isDescending));
      }
    } catch (err) {
      return;
    }

    const asyncActions = [store.dispatch(getMembersList(params))];

    return Promise.all(asyncActions).then(
      () => {
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  bookingFindVehicle({ store, nextState, replaceState, callback }) {
    const { booking } = store.getState();

    if (!booking.selectedMember) {
      const addBookingPath = routes.addBooking.path;
      const bookingFindMemberPath = routes.bookingFindMember.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)));

      replaceState(
        {
          nextPathname: nextState.location.pathname
        },
        `${addBookingPath}/${bookingFindMemberPath}`
      );

      callback();

      return false;
    }

    const {
      params: { search }
    } = nextState;

    if (typeof search === 'undefined') {
      return callback();
    }

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    return store
      .dispatch(getBookingFindAvailableVehicle(params))
      .then(
        () => {
          store.dispatch(getBookingsCustomFields(booking.selectedMember.company.id));
          callback();
        },
        err => {
          callback(err);
        }
      )
      .catch(error => {
        if (error.code === 422) {
          error.body.json().then(data => {
            store.dispatch(
              addFlashMessage({
                contentKey: `bookingResult_${data.errorCode}`,
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
          });
        }
      });
  },

  bookings({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(search);
    } catch (err) {
      return;
    }

    store.dispatch(getBookingsList(params));
    store.dispatch(storeParams(params));
    store.dispatch(resetSideMenu());

    callback();
  },

  bookingsV2({ callback, store, nextState }) {
    store.dispatch(resetSideMenu());
    store.dispatch(getBrandsList()).then(data => {
      const { vehicleBrand } = safe(() => getUriObj(nextState.params.bookingsFilters)) || {};
      const brandId = safe(() => createNameToIdMap(data)[vehicleBrand]);
      if (brandId) store.dispatch(getModelsList(brandId));
    }, fallbackFunc);

    callback();
  },

  bookingsList({ store, callback, nextState }) {
    const state = store.getState();
    const brandsLoading = vehicleBrandsLoadingSelector(state);
    const params = safe(() => shortenBookingId(getUriObj(nextState.params.bookingsFilters)));
    const payload = { ...apiParams.bookingsList, ...params };
    const brandId = safe(() => createNameToIdMap(vehicleBrandsSelector(state))[params.vehicleBrand]);
    const modelId = safe(() => vehicleModelsSelector(state)[0].brand.id);
    if (!brandsLoading && brandId && brandId !== modelId) {
      store.dispatch(getModelsList(brandId));
    }

    store.dispatch(setBookingsFilters(params));
    store.dispatch(getBookingsList(payload, true)).then(callback, callback);
  },

  bookingDetails(props) {
    routeHooks.bookingDetailV2(props);
  },

  companies({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(openSideSubMenu('accountManagement'));
    callback();
  },

  addCompany({ store, callback, isModal }) {
    const promises = [store.dispatch(getAllConfigurationList()), store.dispatch(getInversFleets()), store.dispatch(getCurrencies())];

    if (!isModal) {
      promises.push(store.dispatch(getAppBrands()));
    }

    return Promise.all(promises).then(() => {
      if (!isModal) {
        store.dispatch(openSideSubMenu('accountManagement'));
      }
      callback();
    });
  },

  subCompanies({ store, callback }) {
    const state = store.getState();

    if (state.companies.currentCompany.id === ALL) {
      const firstHeaderCompany = state.companies.headerList[0];
      const { id, name } = _get(state.user.userInfo, 'companies[0]') || {};
      const firstUserCompany = { id, name };

      if (firstHeaderCompany) {
        store.dispatch(setCurrentCompany(firstHeaderCompany));
      } else if (firstUserCompany) {
        store.dispatch(setCurrentCompany(firstUserCompany));
      } else {
        store.dispatch(routeActions.push(routes.dashboard));
      }

      if (state.subCompanies.subCompanySelected) {
        store.dispatch(setSubCompanySelected(COMPANY_ALL));
      }
    }

    return store.dispatch(getSubCompaniesList()).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  account({ store, callback }) {
    store.dispatch(resetSideMenu());
    callback();
  },

  subCompanyDetails({ store, nextState, callback, isModal }) {
    const state = store.getState();
    const {
      params: { subCompanyId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!subCompanyId) throw 'No subCompanyId provided for site details, aborting.';

    const userRole = _get(state.user, 'userInfo.role');
    const { afterPaybackRedirect } = state.api;

    function clearCompanyData() {
      store.dispatch(setSubCompaniesTabIndex(0));
      store.dispatch(subCompanyClearPaybackSettingsData());
    }

    if (afterPaybackRedirect) store.dispatch(afterPaybackRedirectFinish());
    else clearCompanyData();

    function paybackDispatch() {
      const handleResp = () => '';
      return store.dispatch(subCompanyGetPaybackSettingsAction(subCompanyId)).then(handleResp, handleResp);
    }
    let actionsToDispatch = [];
    if (checkRole(subCompanyPaybackConfigTabRules, userRole)) actionsToDispatch.push(paybackDispatch());

    const getStripeAccountDataifNeeded = data => {
      if (data && data.reference) {
        safe(() => {
          const contrat = JSON.parse(data.reference);
          const { payment } = contrat || {};
          const { stripeMigrate } = payment || {};

          if (stripeMigrate) store.dispatch(getSubCompanyConnectedAccount(subCompanyId));
        });
      }
    };

    const accountProvider = data => {
      if (data.paymentProvider === PAYMENT_PROVIDERS.STRIPE) {
        store.dispatch(getSubCompanyConnectedAccount(subCompanyId));
        store.dispatch(getCurrencies());
      }
    };

    store
      .dispatch(getSubCompanyDetails(subCompanyId))
      .then(subCompanyDetails => {
        const { parentCompanyId: parentId } = subCompanyDetails || {};

        actionsToDispatch.push(store.dispatch(getCompanyDetail(parentId)).then(accountProvider));
        actionsToDispatch.push(store.dispatch(getCompanyCurrentContract(parentId)).then(getStripeAccountDataifNeeded));

        if (checkRole(subCompanyPaybackSettingsEditRules, userRole) || checkRole(subCompanyPaybackBankDetailsEditRules, userRole)) {
          actionsToDispatch.push(store.dispatch(getCurrencies()));
        }
        actionsToDispatch.push(store.dispatch(getSubCompanyEmailSetup(subCompanyId)));

        if (userRole !== BACKUSER_ROLE_FLEET_MANAGER) {
          actionsToDispatch.push(store.dispatch(getAllConfigurationList()));
          actionsToDispatch.push(store.dispatch(getSubCompanyInvoiceVatRates(subCompanyId)));
          actionsToDispatch.push(store.dispatch(getSubCompanyInvoiceParams(subCompanyId)));
          actionsToDispatch.push(store.dispatch(getSubCompanyConnectedAccount(subCompanyId)));
        }

        if (!isModal) {
          actionsToDispatch.push(store.dispatch(setParentSuperCompanyInHeader(subCompanyDetails)));
        }

        function showPage() {
          if (!isModal) {
            store.dispatch(openSideSubMenu('accountManagement'));
          }
          callback();
        }

        return Promise.all(actionsToDispatch).then(
          () => {
            showPage();
          },
          err => {
            callback(err);
          }
        );
      })
      .catch(err => {
        callback(err);
      });
  },

  addSubCompany({ store, callback, isModal }) {
    const state = store.getState();

    if (!isModal && state.companies.currentCompany.id === ALL) {
      store.dispatch(setCurrentCompany(state.companies.headerList[0]));
    }

    return store.dispatch(getAllConfigurationList()).then(
      () => {
        callback();

        if (!isModal) {
          store.dispatch(openSideSubMenu('accountManagement'));
        }
      },
      err => {
        callback(err);
      }
    );
  },

  pricing({ store, callback }) {
    const openPage = data => {
      store.dispatch(openSideSubMenu('accountManagement'));
      callback(data);
    };

    const state = store.getState();
    const companyId = validCompanyIdSelector(state);

    if (companyId) {
      const vehicleCategoriesListPromise = store.dispatch(getVehicleCategoriesList());
      const memberTypesPromise = store.dispatch(getMemberTypesList());
      const currentCompanyDetails = store.dispatch(getCompanyDetail(companyId));

      return Promise.all([vehicleCategoriesListPromise, memberTypesPromise, currentCompanyDetails]).then(
        () => openPage(),
        err => openPage(err)
      );
    } else openPage();
  },

  backUserDetail({ store, nextState, callback }) {
    //const { someParam } = routing.params;
    const {
      params: { backUserId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!backUserId) {
      throw 'No backUserId provided for backUser detail, aborting.';
    }

    return store.dispatch(getBackUserDetail(backUserId)).then(
      () => {
        store.dispatch(openSideSubMenu('members'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  memberDetail({ store, nextState, callback }) {
    const {
      params: { memberId }
    } = nextState;

    const showPage = () => {
      store.dispatch(openSideSubMenu('members'));
      scrollToTop();
      callback();
    };

    // TODO: we could notify the user too
    if (!memberId) {
      throw 'No memberId provided for member detail, aborting.';
    }

    return Promise.all([store.dispatch(getMemberDetail(memberId))])
      .then(data => {
        const actionsToWait = [];
        const [info] = data || {};
        const { member: memberDetail, companyId } = info || {};
        const { drivingLicence } = memberDetail || {};
        const params = { memberLogin: memberDetail.login, ...apiParams.default };

        const drivingLicenceStatus = _get(drivingLicence, 'status');

        actionsToWait.push(store.dispatch(getMemberComments(memberId)));
        actionsToWait.push(store.dispatch(getBookingsList(params)));
        actionsToWait.push(store.dispatch(getCustomFieldsMember(memberId)));

        if (companyId) {
          actionsToWait.push(store.dispatch(getSubscriptionCustomFields(companyId)));
          actionsToWait.push(store.dispatch(getMemberTypesList({ companyId: companyId })));
        }

        const driverFiles = _get(drivingLicence, 'files');
        if (_get(driverFiles, 'length', 0) > 0 && !hideDriverImagesForCustomCompanyId(companyId, drivingLicenceStatus)) {
          store.dispatch(getMemberDrivingLicenseImage(driverFiles));
        }

        const identityFiles = _get(memberDetail, 'identityDocument.files');
        if (_get(identityFiles, 'length', 0) > 0) {
          store.dispatch(getMemberIdentityDocumentImage(identityFiles));
        }

        const employerFiles = _get(memberDetail, 'employerCertificate.files');
        if (_get(employerFiles, 'length', 0) > 0) {
          store.dispatch(getMemberEmployerCertificateImage(employerFiles));
        }

        if (_get(memberDetail, 'technician')) {
          actionsToWait.push(store.dispatch(getCompaniesTechnicianList(memberId)));
        }

        Promise.all(actionsToWait)
          .then(() => {
            // all promised data are loaded
            showPage();
          })
          .catch(() => {
            // partial error, wait all promise to finish
          })
          .then(() => {
            showPage();
          });
      })
      .catch(() => {
        // unable to get memberDetail
      });
  },

  memberProfileHistory({ store, nextState, callback }) {
    const {
      params: { memberId }
    } = nextState;

    if (!memberId) {
      throw 'No memberId provided for member detail, aborting.';
    }
    return Promise.all([store.dispatch(getMemberProfileHistory(memberId)), store.dispatch(getMemberDetail(memberId))])
      .then(() => {
        store.dispatch(openSideSubMenu('members'));
        callback();
      })
      .catch(e => callback(e));
  },

  vehicleDetail({ store, nextState, callback }) {
    //const { someParam } = routing.params;
    const {
      params: { vehicleId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!vehicleId) {
      throw 'No vehicleId provided for vehicle detail, aborting.';
    }

    return Promise.all([
      store.dispatch(getLeaseContractsList(vehicleId)),
      store.dispatch(getInsuranceContractsList(vehicleId)),
      store.dispatch(getFuelCardsList(vehicleId)),
      store.dispatch(getAutolibCardsList(vehicleId)),
      store.dispatch(getVehicleDetail(vehicleId)).then(data => {
        const _companyId = safe(() => data.company.id);
        const registrationDocumentId = safe(() => data.registrationDocumentId);
        if (isValidId(registrationDocumentId)) store.dispatch(getRegistrationFileInfo(registrationDocumentId));

        store.dispatch(getSubCompaniesList(_companyId));
        store.dispatch(getSubCompaniesList(_companyId));
        store.dispatch(getCompanyCurrentContract(_companyId));
      })
    ]).then(
      () => {
        store.dispatch(getStatuses(vehicleId));
        store.dispatch(openSideSubMenu('fleet'));
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  companyDetail({ store, nextState, callback, isModal }) {
    const {
      params: { companyId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!companyId) {
      throw 'No companyId provided for company detail, aborting.';
    }

    const state = store.getState();
    const userRole = _get(state.user, 'userInfo.role');
    const { afterPaybackRedirect } = state.api;

    function clearCompanyData() {
      store.dispatch(setCurrentTabIndex(0));
      store.dispatch(companyClearPaybackSettingsData());
    }

    if (afterPaybackRedirect) store.dispatch(afterPaybackRedirectFinish());
    else clearCompanyData();

    function paybackDispatch() {
      const handleResp = () => '';
      return store.dispatch(companyGetPaybackSettingsAction(companyId)).then(handleResp, handleResp);
    }

    const setCompanyDataInHeaderAction = data => {
      if (!isModal) {
        store.dispatch(setSubCompanySelected(COMPANY_ALL));
        store.dispatch(setCurrentSuperCompanyInHeader(data)).catch(() => '');
        store.dispatch(getHeaderSubCompaniesList(companyId)).catch(() => '');
      }
      if (safe(() => data.paymentProvider === PAYMENT_PROVIDERS.STRIPE)) {
        store.dispatch(getConnectedAccount(companyId));
      }
    };

    const getStripeAccountDataifNeeded = data => {
      if (data && data.reference) {
        safe(() => {
          const contrat = JSON.parse(data.reference);
          const { payment } = contrat || {};
          const { stripeMigrate } = payment || {};
          if (stripeMigrate) store.dispatch(getConnectedAccount(companyId));
        });
      }
    };

    let promise = [
      store.dispatch(getCompanyDetail(companyId)).then(setCompanyDataInHeaderAction),
      store.dispatch(getCompanyCurrentContract(companyId)).then(getStripeAccountDataifNeeded),
      store.dispatch(getAllApplicationsListIfNeeded()),
      store.dispatch(getCompanyCustomFields(companyId)),
      store.dispatch(getAllConfigurationList()),
      store.dispatch(getInversFleets()),
      store.dispatch(getCompanyEmailSetup(companyId))
    ];

    if (!isModal) {
      promise.push(store.dispatch(getAppBrands()));
    }

    if (checkRole(superCompanyPaybackConfigTabRules, userRole)) promise.push(paybackDispatch());

    if (checkRole(superCompanyPaybackSettingsEditRules, userRole) || checkRole(superCompanyPaybackBankDetailsEditRules, userRole)) {
      promise.push(store.dispatch(getCurrencies()));
    }

    if (userRole === BACKUSER_ROLE_SUPER_ADMIN || userRole === BACKUSER_ROLE_ROOT || userRole === BACKUSER_ROLE_ADMIN) {
      promise.push(store.dispatch(getInvoiceParams(companyId)));
      promise.push(store.dispatch(getInvoiceVatRateParam(companyId)));
    }

    function showPage() {
      if (!isModal) {
        store.dispatch(openSideSubMenu('accountManagement'));
      }
      callback();
    }

    Promise.all(promise)
      .then(() => {
        showPage();
      })
      .catch(e => {
        console.error('hooks.companyDetail', e);
        // partial error, wait all promise to finish
      })
      .then(() => {
        showPage();
      });
  },

  sites({ store, callback }) {
    const state = store.getState();
    const currentSubCompany = state.subCompanies.subCompanySelected;
    let companyToDisplay = state.companies.currentCompany || {};

    if (companyToDisplay.id === ALL) {
      companyToDisplay = state.companies.headerList[0];
      store.dispatch(setCurrentCompany(companyToDisplay));
    }

    const promise =
      currentSubCompany && currentSubCompany.id !== ALL
        ? [store.dispatch(getSitesListFromSubCompany(currentSubCompany.id))]
        : [store.dispatch(getSitesList())];

    if (!store.subCompanies) {
      promise.push(store.dispatch(getSubCompaniesList(companyToDisplay.id)));
    }

    return Promise.all(promise).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  addSite({ store, callback, isModal }) {
    if (isModal) return callback();
    let state = store.getState();

    if (state.companies.currentCompany.id === ALL) {
      const firstCompany = safe(() => state.companies.headerList[0]);

      store.dispatch(setCurrentCompany(firstCompany));
      store.dispatch(getHeaderSubCompaniesList(safe(() => firstCompany.id)));
    }

    return store.dispatch(getSubCompaniesList()).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  siteDetails({ store, nextState, callback, isModal }) {
    const {
      params: { siteId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!siteId) {
      throw 'No siteId provided for site details, aborting.';
    }

    store.dispatch(getSiteDetails(siteId)).then(
      resp => {
        if (!isModal) {
          store.dispatch(openSideSubMenu('accountManagement'));
          store.dispatch(getParkingsList(siteId));
        }
        store.dispatch(getCompanyDetail(resp.companyId));
        store.dispatch(getSubCompanyDetails(resp.subCompanyId));

        store
          .dispatch(getCompanyCurrentContract(resp.companyId))
          .then(callback)
          .catch(callback);
      },
      err => {
        callback(err);
      }
    );
  },

  addParking({ store, nextState, callback, isModal }) {
    const {
      params: { siteId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!siteId) {
      throw 'No siteId provided for add parking, aborting.';
    }

    return Promise.all([
      store.dispatch(getSiteDetails(siteId)).then(data => {
        store.dispatch(getCompanyDetail(safe(() => data.companyId)));
        store.dispatch(getSubCompanyDetails(safe(() => data.subCompanyId)));
      }),
      store.dispatch(getBankHolidays(getBankHolidaysDate()))
    ]).then(
      () => {
        if (!isModal) {
          store.dispatch(openSideSubMenu('accountManagement'));
        }
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  parkingDetails({ store, nextState, callback, isModal }) {
    const {
      params: { parkingId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!parkingId) {
      throw 'No parkingId provided for parking details, aborting.';
    }

    const menu2Open = store.getState().sideMenu.isFleetOpen;

    return Promise.all([
      store.dispatch(getParkingDetails(parkingId)).then(data => {
        store.dispatch(getCompanyDetail(safe(() => data.site.companyId)));
        store.dispatch(getSubCompanyDetails(safe(() => data.site.subCompanyId)));
      }),
      store.dispatch(getBankHolidays(getBankHolidaysDate()))
    ]).then(
      () => {
        if (!isModal) {
          store.dispatch(openSideSubMenu(menu2Open ? 'fleet' : 'accountManagement'));
        }
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  contractV2({ store, callback }) {
    const state = store.getState();
    const companyId = currentCompanyIdSelector(state);

    if (isValidId(companyId)) {
      store.dispatch(getCompanyCurrentContract(companyId));
    } else if (isLocalEnv()) {
      const selectedCompany = headerCompanyListSelector(state)[0];
      store.dispatch(setCurrentCompany(selectedCompany));
      store.dispatch(getCompanyCurrentContract(selectedCompany.id));
    }

    store.dispatch(openSideSubMenu('accountManagement'));
    callback();
  },

  contract({ store, callback }) {
    let state = store.getState();

    let companyId;
    if (state.companies.currentCompany && state.companies.currentCompany.id === ALL) {
      store.dispatch(setCurrentCompany(state.companies.headerList[0]));
      companyId = state.companies.headerList[0].id;
    } else {
      companyId = state.companies.currentCompany.id;
    }

    if (state.subCompanies.subCompanySelected) {
      store.dispatch(setSubCompanySelected(COMPANY_ALL));
    }

    return store.dispatch(getCompanyCurrentContract(companyId)).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  resetPassword({ store, nextState, callback }) {
    const {
      params: { resetPasswordToken }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!resetPasswordToken) {
      throw 'No resetPasswordToken provided, aborting.';
    }

    return store.dispatch(checkResetPasswordTokenRequest(resetPasswordToken)).then(
      () => {
        store.dispatch(storeResetPasswordToken(resetPasswordToken));

        callback();
      },
      () => {
        store.dispatch(
          addFlashMessage({
            contentKey: 'resetPassword_token_expired',
            type: FLASH_MESSAGE_TYPE_ERROR,
            delayToNextRoute: true
          })
        );
        store.dispatch(routeActions.push(routes.login.path));
      }
    );
  },

  editVehicle({ store, nextState, callback }) {
    const {
      params: { vehicleId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!vehicleId) {
      throw 'No vehicleId provided for vehicle edit, aborting.';
    }

    store.dispatch(storeParams(vehicleId));

    store.dispatch(openSideSubMenu('fleet'));

    store.dispatch(initCreateVehicleForm());

    callback();
  },

  brands({ store, callback }) {
    return store.dispatch(getBrandsList()).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  vehiclesColors({ store, callback }) {
    return store.dispatch(getColors()).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  editVehiclesColor({ store, nextState, callback }) {
    const {
      params: { colorId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!colorId) {
      throw 'No colorId provided for edit color, aborting.';
    }

    return store.dispatch(getSingleColor(colorId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  addVehiclesColor({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));

    callback();
  },

  addBrand({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));

    callback();
  },

  editBrand({ store, nextState, callback }) {
    const {
      params: { brandId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!brandId) {
      throw 'No brandId provided for edit brand, aborting.';
    }

    return store.dispatch(getSingleBrand(brandId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        store.dispatch(getModelsList(brandId));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  vehiclesCategories({ store, callback }) {
    return store.dispatch(getCategoriesRequest()).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  editVehiclesCategory({ store, nextState, callback }) {
    const {
      params: { categoryId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!categoryId) {
      throw 'No categoryId provided for edit category, aborting.';
    }

    return store.dispatch(getSingleCategory(categoryId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  addVehiclesCategory({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));

    callback();
  },

  addModel({ store, nextState, callback }) {
    const {
      params: { brandId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!brandId) {
      throw 'No brandId provided for add model, aborting.';
    }

    return store.dispatch(getSingleBrand(brandId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  editModel({ store, nextState, callback }) {
    const {
      params: { modelId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!modelId) {
      throw 'No modelId provided for edit model, aborting.';
    }

    return store.dispatch(getSingleModel(modelId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        store.dispatch(getVersionsList(modelId));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  addVersion({ store, nextState, callback }) {
    const {
      params: { modelId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!modelId) {
      throw 'No modelId provided for add version, aborting.';
    }

    return store.dispatch(getSingleModel(modelId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  editVersion({ store, nextState, callback }) {
    const {
      params: { versionId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!versionId) {
      throw 'No versionId provided for edit version, aborting.';
    }

    return store.dispatch(getSingleVersion(versionId)).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      () => {
        callback();
      }
    );
  },

  feedbacks({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
      /* if (params.sort) {
        store.dispatch(setFeedbacksCurrentSortedIndex(params.sort.property));
        store.dispatch(setFeedbacksSortIsDescending(params.sort.isDescending));
      }*/
      store.dispatch(storeParams(params));
    } catch (err) {
      return;
    }

    store.dispatch(openSideSubMenu('fleet'));
    callback();
  },

  feedbackDetail({ store, nextState, callback }) {
    const {
      params: { feedbackId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!feedbackId) {
      throw 'No feedbackId provided for feedback detail, aborting.';
    }

    return store.dispatch(getFeedbackDetail(feedbackId)).then(
      data => {
        if (data.report && data.report.fileId) {
          store.dispatch(getFeedbackReportImage(data.report.fileId));
        }

        store.dispatch(openSideSubMenu('fleet'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  editBackUser({ store, nextState, callback }) {
    const {
      params: { backUserId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!backUserId) {
      throw 'No backUserId provided for edit back user, aborting.';
    }

    return store.dispatch(getBackUserDetail(backUserId)).then(
      () => {
        store.dispatch(openSideSubMenu('members'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  dashboardV2(props) {
    return routeHooks.dashboard(props);
  },

  dashboard({ store, callback }) {
    store.dispatch(dashboardResetItemsCount());

    return Promise.all([
      store.dispatch(getLastBookings()),
      store.dispatch(getDelayedBookings()),
      store.dispatch(getFailedBookings()),
      store.dispatch(getNonValidatedMembers()),
      store.dispatch(getUnmanagedDamages()),
      store.dispatch(getCleanlinessProblems()),
      store.dispatch(getExpediteMembers()),
      store.dispatch(getlowAccessoryBatVehicles()),
      store.dispatch(getNonOperatingVehicles()),
      store.dispatch(getLowFuelLevelVehicles()),
      store.dispatch(getFailedRrsBookings())
    ])
      .then(() => {
        store.dispatch(resetSideMenu());
        callback();
      })
      .catch(() => {
        store.dispatch(resetSideMenu());
        callback();
      });
  },

  vehicleStatuses({ nextState, store, callback }) {
    const {
      params: { search }
    } = nextState;

    try {
      const params = JSON.parse(decodeURIComponent(search));
      store.dispatch(getVehicleStatusesList(params));
    } catch (err) {}

    store.dispatch(openSideSubMenu('reports'));
    callback();
  },

  quickSight({ store, callback }) {
    store.dispatch(openSideSubMenu('reports'));
    callback();
  },

  bookingDetail(props) {
    routeHooks.bookingDetailV2(props);
  },

  bookingDetailV2({ store, nextState, callback }) {
    const {
      params: { bookingId }
    } = nextState;

    if (!bookingId) throw 'No bookingId provided for booking detail, aborting';

    function showPage() {
      callback();
    }

    return store.dispatch(getSingleBooking(bookingId)).then(
      data => {
        const actionsToWait = [];
        const vehicleSuperCompanyId = safe(() => data.vehicle.company.id);
        const vehicleSubCompanyId = _get(data, 'vehicle.lastPosition.parking.site.subCompanyId');
        const bookingId = _get(data, 'id');
        const files = _get(data, 'drivingLicenceMetaData.files');
        const externalInvoice = safe(() => data.member.company.useExternalInvoiceSystem);
        const proplannerErrors = safe(() => data.integrationFailed);

        if (!data.carSharingInfo && delayedBookingStatuses.includes(safe(() => data.status))) {
          store.dispatch(getBookingDelayedStatus(bookingId));
        }
        if (proplannerErrors) actionsToWait.push(store.dispatch(getProplannerErrors(bookingId)));
        actionsToWait.push(store.dispatch(bookingViewGetInvoices(bookingId)));
        actionsToWait.push(store.dispatch(getBookingTransactions(bookingId, true)));

        if (externalInvoice === EXTERNAL_INVOICE.DMS) {
          actionsToWait.push(store.dispatch(getDmsInvoiceErrors(bookingId)));
        }

        if (externalInvoice === EXTERNAL_INVOICE.ITALIAN) {
          actionsToWait.push(store.dispatch(getItalianInvoiceStatuses(bookingId)));
        } else {
          actionsToWait.push(store.dispatch(clearItalianInvoiceStatus()));
        }

        if (vehicleSubCompanyId) {
          actionsToWait.push(store.dispatch(getVehicleCompany(vehicleSubCompanyId, true)));
        }

        if (!hideDriverImagesForCustomCompanyId(vehicleSuperCompanyId, STATUS_APPROVED)) {
          actionsToWait.push(store.dispatch(getBookingLicenseImage(files)));
        }

        actionsToWait.push(store.dispatch(getCompanyCurrentContract(vehicleSuperCompanyId)));

        Promise.all(actionsToWait)
          .then(() => {
            // all promised data are loaded
            showPage();
          })
          .catch(() => {
            // partial error, wait all promise to finish
          })
          .then(() => {
            showPage();
          });
      },
      err => {
        callback(err);
      }
    );
  },

  bookingEventsHistory({ store, nextState, callback }) {
    const {
      params: { bookingId }
    } = nextState;

    if (!bookingId) throw 'No bookingId provided for booking events detail, aborting';

    function showPage(data) {
      callback(data);
    }

    return store.dispatch(requestHistoryEventsBooking(bookingId)).then(showPage, showPage);
  },

  support({ store, callback }) {
    store.dispatch(openSideSubMenu('generalSettings'));
    callback();
  },

  hotlinesV2({ store, callback }) {
    return store
      .dispatch(getHotlines())
      .then(() => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      })
      .catch(callback);
  },

  hotlineEdit({ store, nextState, callback }) {
    const { hotlineId } = nextState.params || {};
    return store.dispatch(getHotlineDetail(hotlineId)).then(...hideErrors(callback));
  },

  configurationsV2({ store, callback }) {
    return store
      .dispatch(getConfigurationList())
      .then(() => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      })
      .catch(callback);
  },

  configurationAdd({ store, callback }) {
    return Promise.all([store.dispatch(getHotlines()), store.dispatch(getAllConfigurationList())]).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  configurationEdit({ store, nextState, callback }) {
    const {
      params: { configurationId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!configurationId) {
      throw 'No configurationId provided for edit fine, aborting.';
    }

    const state = store.getState();
    const role = userRoleSelector(state);

    const promise = [store.dispatch(getConfigurationDetail(configurationId)), store.dispatch(getAllConfigurationList())];

    if (checkRole(hotlineRules, role)) {
      promise.push(store.dispatch(getHotlines()));
    }

    return Promise.all(promise).then(
      () => {
        store.dispatch(openSideSubMenu('generalSettings'));
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  configurations({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    return store.dispatch(getConfigurationList(params)).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  addConfiguration({ store, callback }) {
    return Promise.all([store.dispatch(getHotlines()), store.dispatch(getAllConfigurationList())]).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  editConfiguration({ store, nextState, callback }) {
    const {
      params: { configurationId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!configurationId) {
      throw 'No configurationId provided for edit fine, aborting.';
    }

    return Promise.all([
      store.dispatch(getConfigurationDetail(configurationId)),
      store.dispatch(getHotlines()),
      store.dispatch(getAllConfigurationList())
    ]).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  hotlines({ store, callback }) {
    return store.dispatch(getHotlines()).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  editHotline({ store, nextState, callback }) {
    const {
      params: { hotlineId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!hotlineId) {
      throw 'No hotlineId provided for edit color, aborting.';
    }

    return store.dispatch(getHotlineDetail(hotlineId)).then(
      () => {
        store.dispatch(openSideSubMenu('accountManagement'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  addHotline({ store, callback }) {
    store.dispatch(openSideSubMenu('accountManagement'));

    callback();
  },

  vehicleDamages({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));

      if (params.sort) {
        store.dispatch(setVehicleDamagesCurrentSortedIndex(params.sort.property));
        store.dispatch(setVehicleDamagesSortIsDescending(params.sort.isDescending));
      }
    } catch (err) {
      return;
    }

    return store.dispatch(getVehicleDamagesList(params)).then(
      () => {
        store.dispatch(openSideSubMenu('fleet'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  vehicleDamageDetail({ store, nextState, callback }) {
    const {
      params: { vehicleDamageId }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!vehicleDamageId) {
      throw 'No vehicleDamageId provided for vehicle damage detail, aborting.';
    }

    return store.dispatch(getVehicleDamageDetail(vehicleDamageId)).then(
      () => {
        store.dispatch(openSideSubMenu('fleet'));

        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  invoiceFindBooking({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));

      params.states = ['PAST'];

      if (params.sort) {
        store.dispatch(setInvoiceFindBookingCurrentSortedIndex(params.sort.property));
        store.dispatch(setInvoiceFindBookingSortIsDescending(params.sort.isDescending));
      }
    } catch (err) {
      return;
    }
    let asyncActions = [store.dispatch(getBookingsList(params, true))];

    return Promise.all(asyncActions).then(
      () => {
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  createInvoice({ store, nextState, replaceState, callback }) {
    const { invoices } = store.getState();

    if (!invoices.selectedBooking) {
      const addInvoicePath = routes.addInvoice.path;
      const invoiceFindBookingPath = routes.invoiceFindBooking.path.replace(
        ':search',
        encodeURIComponent(JSON.stringify(apiParams.default))
      );

      replaceState(
        {
          nextPathname: nextState.location.pathname
        },
        `${addInvoicePath}/${invoiceFindBookingPath}`
      );

      callback();

      return false;
    }

    callback();
  },

  vouchers({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;

    let params;

    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));
    store.dispatch(resetSideMenu());

    callback();
  },

  invoices({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;
    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }
    store.dispatch(storeParams(params));

    store.dispatch(resetSideMenu());
    callback();
  },

  invoicesV2({ callback, store }) {
    store.dispatch(resetSideMenu());
    callback();
  },

  invoicesList({ store, callback, nextState }) {
    const params = safe(() => getUriObj(nextState.params.invoicesFilters));
    const { bookingId, ...rest } = params || {};

    const search = payload => {
      const filters = shortenBookingId(payload);
      store.dispatch(setInvoicesFilters(filters));
      store.dispatch(getInvoicesList(payload, { withPdf: false })).then(callback);
    };

    if (regexShortId.test(bookingId)) {
      store.dispatch(searchBookings(bookingId)).then(
        data => {
          const id = safe(() => data.results[0].id);
          if (id) search({ ...rest, bookingId: id });
          else search(rest);
        },
        () => search(rest)
      );
    } else {
      search(params);
    }
  },

  invoiceDetails({ store, callback, nextState }) {
    const ref = safe(() => decodeURIComponent(nextState.params.invoiceRef));
    store.dispatch(getSingleInvoice(ref)).then(invoice => {
      if (invoice) {
        store.dispatch(getSingleBooking(invoice.bookingId));
      }
      callback();
    });
  },

  bankouts({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;
    let params;
    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      return;
    }

    store.dispatch(storeParams(params));

    store.dispatch(resetSideMenu());
    callback();
  },

  addVoucherGroup({ callback, store }) {
    const companyId = validCompanyIdSelector(store.getState());

    function getData() {
      store.dispatch(getVouchersCompanyParkings());
      store.dispatch(getVouchersCompanies());
    }

    if (companyId) {
      getData(companyId);
    }

    callback();
  },

  voucherGroupDetail({ store, nextState, callback }) {
    const {
      params: { search, voucherGroupId: id }
    } = nextState;

    let params;

    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {
      params = apiParams.default;
    }

    store.dispatch(getBookingsCountByVoucherUses(id));
    store.dispatch(getVouchers({ voucherGroupId: id, ...params }));
    store.dispatch(storeParams(params));

    store.dispatch(getVoucherGroupDetail(id)).then(data => {
      store.dispatch(getSubCompaniesList(data.superCompanyId));
      store.dispatch(getCompany(data.superCompanyId));
    });

    callback();
  },

  bankoutDetails({ store, nextState, callback }) {
    const {
      params: { search }
    } = nextState;
    let params = apiParams.default;

    try {
      params = JSON.parse(decodeURIComponent(search));
    } catch (err) {}

    store.dispatch(storeParams(params));

    callback();
  },

  vehiclePlanning({ store, callback }) {
    const state = store.getState();
    const subCompanyId = _get(state, 'subCompanies.subCompanySelected.id');
    const companyId = _get(state, 'companies.currentCompany.id');

    if (subCompanyId && subCompanyId !== ALL) {
      store.dispatch(getVpData(companyId, subCompanyId));
    } else {
      store.dispatch(vpDevCompanySelect());
    }

    store.dispatch(resetSideMenu());
    callback();
  },

  vehiclePlanningV2({ store, callback }) {
    const state = store.getState();
    const subCompanyId = _get(state, 'subCompanies.subCompanySelected.id');
    const companyId = _get(state, 'companies.currentCompany.id');

    if (subCompanyId && subCompanyId !== ALL) {
      store.dispatch(getVpData(companyId, subCompanyId));
    } else {
      store.dispatch(vpDevCompanySelect());
    }

    store.dispatch(resetSideMenu());
    callback();
  }
};
