import j from 'jquery';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _forOwn from 'lodash/forOwn';
import _isString from 'lodash/isString';
import _includes from 'lodash/includes';
import _compact from 'lodash/compact';
import _isDate from 'lodash/isDate';
import _forEachRight from 'lodash/forEachRight';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _mapValues from 'lodash/mapValues';
import _isObject from 'lodash/isObject';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';
import merge from 'merge-deep';
import classnames from 'classnames';
import config from '../constants/config-constants';
import configConstants from '../constants/config-constants';
import memoizeOne from 'memoize-one';
import rootConfig from '../../../config/config';
import themeConstants from '../constants/theme-constants';
import moment from 'moment';
import React from 'react';
import momentTz from 'moment-timezone';
import compose from 'recompose/compose';
import setDisplayName from 'recompose/setDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { getValues } from 'redux-form';
import mime from 'mime-types';

import { MINUTES_BEFORE_CHANGE_STATUS } from '../constants/business-constants';
import {
  ALL,
  COMPANY_TYPE_SUB,
  COMPANY_TYPE_SUPER,
  FLASH_MESSAGE_TYPE_ERROR,
  INVOICE_DETAILS_TOTAL,
  ISO_DATE_FULL,
  ISO_DATE_LOCAL_SSS,
  localEnvs,
  MATERIAL_LOCALE_MAP,
  OS_LINUX,
  OS_MAC,
  OS_UNIX,
  OS_UNKNOWN,
  OS_WINDOWS,
  STORAGE_KEY,
  themeToLocalBoUrlBrandMap,
  themeToLocalFoUrlBrandMap,
  validEnvs
} from '../constants/generic-constants';

import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_STATUS_SCHEDULED,
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_YES,
  ONE_WAY_TRIP,
  ROUND_TRIP,
  STATUS_CANCELED,
  STATUS_TO_REVIEW,
  URL_ALL,
  VEHICLE_USAGE_TYPE_DELIVERY,
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_TEST_DRIVE
} from '../constants/backend-constants';

import { integer, length, maximum, minimum, notEmpty, number } from '../validation/sync-validation';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../constants/form-constants';
import {
  brandThemeSelector,
  bundleSelector,
  currentCompanyIdSelector,
  currentSubCompanyIdSelector,
  headerCompanyContractSelector,
  userCompanyIdsSelector,
  userHeaderSubCompanyIdsSelector,
  userRoleSelector
} from '../selectors/all-selectors';

// noinspection ES6CheckImport
import { saveAs } from 'file-saver';

import { errorCodes } from '../constants/options-constants';
import { addFlashMessage } from '../actions/flashMessage-actions';
import { checkRole, renaultItalyRoles, subCompanyListRules, subCompanySelectRules } from '../constants/backuser-role-rules';
import _memoize from 'lodash/memoize';
import { getStore } from '../store/all-store';
import { cleanDeep } from './cleanDeep';
import { requestMemberTypeChange } from '../actions/members-actions';
import { displayBookingPrice, getEstimatedPriceBooking, isGranted } from '../actions/all-actions';
import { FormattedMessage } from 'react-intl';
import { regexGuid, regexMultiSpace } from '../constants/regex';
import routes, { companyRules, subCompanyRules } from '../constants/routes-constants';
import humanize from 'humanize-duration';
import { getMsg } from './IntlGlobalProvider';

// Number.isNaN not supported on IE 11
export function numIsNaN(n) {
  // noinspection JSCheckFunctionSignatures
  return typeof n === 'number' && isNaN(n);
}

// checks if passed value is empty
export function isEmpty(value) {
  return value === undefined || value === null || value === '' || value === '-' || numIsNaN(value);
}

export function isValidEmail(value) {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(value);
}

export function isValidPhoneNumber(phoneNumberObject) {
  const { valid } = phoneNumberObject || {};
  return valid;
}

export function hex2rgb(hexValue) {
  if (hexValue.length !== 7) {
    throw 'Only seven characters hex colors are allowed.';
  }
  const v = hexValue.split('#');

  const aRgbHex = v[1].match(/.{1,2}/g);
  const aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
  return `${aRgb[0]},${aRgb[1]}, ${aRgb[2]}`;
}

export const customFieldTypes = [
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_FILE
];

// get default app [date format]
export function getAppDateFormat({ short, v2 } = {}) {
  return (v2 ? 'DD/MM/' : 'DD.MM.') + (short ? 'YY' : 'YYYY');
}

// ex: tuesday 01 december 2021
export function getAppDateFormatVP(short = false) {
  return 'dddd DD MMMM ' + (short ? 'YY' : 'YYYY');
}

// get default app [time format]
export function getAppTimeFormat(v2, timeLong = false) {
  return v2 ? 'HH[h]mm' + (timeLong ? '[m]ss' : '') : timeLong ? 'HH:mm:ss' : 'HH:mm';
}

// get default app [day month format]
export function getAppDayMonthFormat() {
  return 'DD/MM';
}

// format passed date to display in local or original time zone
export function formatAppDate(date, format, local = false) {
  if (date) {
    const momentDate = moment(date);
    const isValid = momentDate.isValid();
    const parsedDate = local ? momentDate.local() : momentDate.parseZone();
    return isValid ? parsedDate.format(format || getAppDateFormat()) : date;
  }
}

// get [time] in default format for display purposes
export function getAppFormattedTime(date, { local, v2 } = {}) {
  return date && formatAppDate(date, getAppTimeFormat(v2), local);
}

// get [day month time] in default format for display purposes
export function getAppFormattedDayMonthTime(date, { local, v2 } = {}) {
  return date && formatAppDate(date, getAppDayMonthFormat() + ' ' + getAppTimeFormat(v2), local);
}

// get [date] in default format for display purposes
export function getAppFormattedDate(date, { short, local, v2 } = {}) {
  return date && formatAppDate(date, getAppDateFormat({ short, v2 }), local);
}

//  get [date + time] in default format for display purposes
export function getAppFormattedDateTime(date, { short, local, v2, longTime } = {}) {
  return date && formatAppDate(date, getAppDateFormat({ short, v2 }) + ' ' + getAppTimeFormat(v2, longTime), local);
}

// helper to compute diff between dates
export function localTime(date) {
  const tz = momentTz.tz.guess();
  return date && momentTz.utc(date).tz(tz);
}

// used for API requests
export function localToUTC(date) {
  const tz = momentTz.tz.guess();
  return date && momentTz.tz(date, tz).format();
}

// used for API requests
export function momentTimeFormat(hours, minutes) {
  return moment()
    .startOf('day')
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .format('HH:mm');
}

// used for API requests
export function dateToYMD(date) {
  if (!date) return;
  date = moment(date);
  if (!date.isValid()) return;
  return date.format('YYYY-MM-DD');
}

// used for API requests
export function formatCustomDate(date, format) {
  return date && moment(date).format(format);
}

// used for API requests
export function minDateFormat(date, format) {
  return (
    date &&
    moment(date)
      .startOf('day')
      .format(format)
  );
}

// used for API requests
export function maxDateFormat(date, format) {
  return (
    date &&
    moment(date)
      .endOf('day')
      .format(format)
  );
}

// used for API requests
export function formatDate(date) {
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return [year, month, day].join('-');
}

// used for API requests
export function formatDateLong(date) {
  let dateMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
  let dateDay = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  let dateHour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
  let dateMin = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
  let dateSec = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
  let dateMilliSec = '000';

  return date.getFullYear() + '-' + dateMonth + '-' + dateDay + 'T' + dateHour + ':' + dateMin + ':' + dateSec + '.' + dateMilliSec;
}

// used for API requests
export function stringDateFormat(string) {
  let [date] = string.split('T');
  return date;
}

// used for API requests
export function getTimeByType(string, type) {
  let time = string.split(':');

  if (type === 'hours') {
    return time[0];
  } else if (type === 'minutes') {
    return time[1];
  }
}

// used for API requests
export function dateToISOString(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

// checks if passed value is time '12:25:000z'
export function isValidTime(t) {
  const regex = RegExp('^([0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
  return regex.test(t);
}

// will check if the date have proper format | else try to autoformat | else return passed data
export function checkDate(any, format) {
  if (!any) return any;
  let date = moment(any, format, true);
  if (date.isValid()) {
    if (_isString(any)) return any;
    else return date.format(format);
  } else {
    date = moment(any);
    if (date.isValid()) return date.format(format);
  }
  return any;
}

export function setNewEndDate(newDate, newTime) {
  const timeArr = newTime.split(':');
  const hour = timeArr[0];
  const min = timeArr[1];
  return localToUTC(moment(new Date(newDate)).set({ hour: hour, minute: min, millisecond: 0 })); // eslint-disable-line
}

// used for API requests
export function createDateForCreateBooking(date, hour, min) {
  return localToUTC(moment(date).set({ hour: hour, minute: min })); // eslint-disable-line
}

export function createDateGetBookingCost(date, hour, min) {
  return moment(date)
    .set({ hour: hour, minute: min })
    .format(ISO_DATE_LOCAL_SSS);
}

// used for API requests
export function getBankHolidaysDate() {
  return {
    start: moment().format('YYYY-MM-DD'),
    end:
      moment().format('MM') !== '12'
        ? moment()
            .endOf('year')
            .format('YYYY-MM-DD')
        : moment()
            .add(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD')
  };
}

// used for API request
export function toEventLogDate(timeOfDay) {
  return date => {
    const momentDate = moment(date);
    if (timeOfDay === 'min') {
      momentDate.startOf('day');
    } else {
      momentDate.endOf('day');
    }
    return momentDate.format('YYYY-MM-DDTHH:MM:ss');
  };
}

// used for API requests
export function dateToStringFormat(date, format = ISO_DATE_FULL) {
  date = moment(date);
  return date.isValid() ? date.format(format) : date;
}

// used for API requests
export function datesToString(obj, format) {
  const newObj = { ...obj };
  const dates = _pickBy(obj, _isDate);

  _forOwn(dates, (date, key) => {
    newObj[key] = dateToStringFormat(date, format);
  });

  return _isEmpty(dates) ? obj : newObj;
}

export function fromEventLogDate(string) {
  return moment(string).toDate();
}

export const nextValidMinute = () => {
  const date = moment();
  return (Math.ceil(date.minute(date.minute() + MINUTES_BEFORE_CHANGE_STATUS + 1).minute() / 15) * 15) % 60;
};

export const nextValidHour = () => {
  const date = moment();
  return date.minute(date.minute() + 2 * MINUTES_BEFORE_CHANGE_STATUS).hour();
};

export const nextValidDate = () => {
  const date = moment();

  date.minute(date.minute() + 2 * MINUTES_BEFORE_CHANGE_STATUS);
  date.second(0);
  return date.toDate();
};

export function getDaysByLocale(days, bundle) {
  let daysString = '';

  days.split(',').map((item, index) => {
    daysString += bundle['dayOfWeek_' + item.trim()];

    if (index !== days.split(',').length - 1) daysString += ', ';
  });

  return daysString;
}

export function createCustomFieldsValidators(customFields = [], usage) {
  return customFields.reduce((result, field = {}) => {
    const { vehicleUsages = [], fieldType } = field;

    const wrongType = !customFieldTypes.includes(fieldType);
    const wrongUsage = vehicleUsages.length && !vehicleUsages.includes(usage);

    if (wrongType || wrongUsage) {
      return result;
    }

    const validators = [];
    const { min, max, id } = field;

    if (field.mandatory === CUSTOM_FIELD_YES) {
      validators.push(notEmpty());
    }
    if (fieldType === CUSTOM_FIELD_TYPE_NUMERIC) {
      validators.push(number());
      if (typeof min === 'number') validators.push(minimum(min));
      if (typeof max === 'number') validators.push(maximum(max));
    }
    if (fieldType === CUSTOM_FIELD_TYPE_PHONE_NUMBER) {
      validators.push(integer());
    }
    if (fieldType === CUSTOM_FIELD_TYPE_TEXT) {
      validators.push(length({ min: min || 0, max: max || 255 }));
    }
    if (id) {
      result[id] = validators;
    }

    return result;
  }, {});
}

export function createCustomFieldsValues(customFields, values) {
  return customFields.reduce((filtered, field) => {
    const newProps = {};

    if (_get(values, _get(field, 'id'))) {
      newProps.companyCustomFieldId = field.id;
      newProps.value = values[field.id];
      filtered.push(newProps);
    }

    return filtered;
  }, []);
}

export function getSearchParams() {
  return window.location.hash.split('?')[1];
}

export function clearQueryParams() {
  window.location.href = window.location.href.split('?')[0];
}

export function createCustomFieldsNames(customFields) {
  return _compact(_map(customFields, field => _get(field, 'id')));
}

export const booleanColumn = predicate => ({
  contentMessageKey: 'common_[content]',
  content: item => (predicate(item) ? 'yes' : 'no')
});

export function valuesToMoment({ dateName = 'date', hourName = 'hour', minuteName = 'minute' }) {
  return values =>
    !isEmpty(values[dateName]) &&
    !isEmpty(values[hourName]) &&
    !isEmpty(values[minuteName]) &&
    moment(values[dateName])
      .hour(values[hourName])
      .minute(values[minuteName]);
}

export const getParamsFromQuery = memoizeOne(query => {
  const result = {};

  if (query) {
    const params = query.split('&');

    for (let i = 0; i < params.length; i++) {
      const { [0]: key, [1]: value } = params[i].split('=');
      result[key] = decodeURIComponent(value);
    }
  }

  return result;
});

export function getQueryParams() {
  return getParamsFromQuery(getSearchParams());
}

export function isSmartcardActive({ activationDate = null, deactivationDate = null }) {
  const activeSince = new Date(activationDate).getTime();
  const unactiveSince = new Date(deactivationDate).getTime();
  const now = new Date().getTime();

  return (now > activeSince && now < unactiveSince) || activeSince > unactiveSince;
}

export function getShortId(id) {
  return _isString(id) ? id.split('-')[0] : id;
}

export function pickTruthy(object, keys) {
  return _pickBy(object, (v, k) => _includes(keys, k) && v);
}

export function renameKey(source, newKey, oldKey) {
  return delete Object.assign(source, { [newKey]: source[oldKey] })[oldKey];
}

// select current super company | if ALL is selected return undefined
export function selectCompanyId(state) {
  const currentId = currentCompanyIdSelector(state);
  return currentId !== ALL ? currentId : undefined;
}

// select current sub company | if ALL is selected return undefined
export function selectSubCompanyId(state) {
  const currentId = currentSubCompanyIdSelector(state);
  return currentId !== ALL ? currentId : undefined;
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

function getItemPriceWithVAT(priceWithVat, quantity) {
  return Number(parseFloat(quantity * priceWithVat).toFixed(2));
}

export function invoiceItemFormat(item) {
  if (item) {
    return {
      description: item.description,
      quantity: Number(item.quantity),
      pricePerUnitWithVat: Number(item.pricePerUnitWithVat),
      itemPriceWithVat: getItemPriceWithVAT(Number(item.pricePerUnitWithVat), Number(item.quantity))
    };
  }

  return null;
}

export function addInvoiceItemsTotal(invoiceItems, totalPrices) {
  let invoiceItemsWithTotal = [...invoiceItems];

  invoiceItemsWithTotal.push({
    description: null,
    quantity: null,
    translateKey: INVOICE_DETAILS_TOTAL,
    itemPriceWithVat: totalPrices.totalItemPriceWithVat
  });

  return invoiceItemsWithTotal;
}

export function roundToTwoDecimals(value) {
  const match = (value + '').match(/(?:\.(\d+))?$/);

  if (!match || !match[1]) {
    return value;
  }

  return value.toFixed(2);
}

export function downloadUrlContent(url, filename) {
  let a = document.createElement('a');
  a.href = url;
  a.download = filename || '';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function openImageNewTab(url, alt = '') {
  const win = window.open();
  win.document.open('text/html');
  win.document.write(`<img src="${url}" alt="${alt}" >`);
}

export function openPdfNewTab(data) {
  try {
    const win = window.open();
    win.document.open('text/html');
    win.document.write(`<iframe width="100%" height="100%" style="border: 0" src="${data}"></iframe>`);
    win.document.body.setAttribute('style', 'padding: 0; margin: 0;');
    win.document.documentElement.setAttribute('style', 'padding: 0; margin: 0;');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('could not open new tab');
  }
}

export function sortBoxedSelectAlphabetically(options) {
  return options.sort(function(a, b) {
    let valueA, valueB;
    if (typeof a === 'object' || typeof b === 'object') {
      valueA = a.label;
      valueB = b.label;
    } else {
      valueA = a;
      valueB = b;
    }
    let textA = typeof valueA === 'string' || valueA instanceof String ? valueA.toUpperCase() : valueA,
      textB = typeof valueB === 'string' || valueB instanceof String ? valueB.toUpperCase() : valueB;
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
}

export function mapFilterValueFromObject(data = {}) {
  return Object.keys(data).map(obj => ({ value: data[obj].name }));
}

export function formatRouteLocation(location, foundArray) {
  if (foundArray.length === 1) {
    return location.substr(foundArray[0] + 1, location.length);
  } else {
    return location.substr(foundArray[0] + 1, foundArray[1] - 1);
  }
}

export function regexEscape(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function fillReactArray(component, len) {
  let array = [];

  for (let i = 0; i < len; i++) {
    array.push(React.cloneElement(component, { key: i }));
  }
  return array;
}

export function reactArrayFromObject(reactObject, nTimes, wrapperClass) {
  let array = [];

  wrapperClass = wrapperClass ? wrapperClass : '';
  for (let i = 0; i < nTimes; i++) {
    array.push(
      <div key={i} className={wrapperClass}>
        {reactObject}
      </div>
    );
  }

  return array;
}
export function base64ToMimeType(base64) {
  // Decoding base64 without any data prefix
  try {
    const binary = window.atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    // Extract first four bytes (magic number)
    const magicNumbers = bytes.subarray(0, 4).reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');
    // Common file type magic numbers
    if (magicNumbers.startsWith('89504e47')) {
      return 'image/png';
    } else if (magicNumbers.startsWith('ffd8ff')) {
      return 'image/jpeg';
    } else if (magicNumbers.startsWith('25504446')) {
      return 'application/pdf';
    }
  } catch (e) {
    return 'unknown';
  }
}

export function translateChipsValues(intl, value, label, filterTypes) {
  const filterObject = safe(() => filterTypes[label]);
  const filterType = safe(() => filterObject['type']);

  if (filterType === 'select') {
    const selectType = typeof value === 'string' || typeof value === 'boolean';
    const filterOptions = safe(() => filterObject['options']);

    const optionObject = _find(filterOptions, item => {
      const option = safe(() => item['value']);

      if (typeof value !== typeof option && selectType) {
        return toBoolean(value) === toBoolean(option);
      }
      return value === option;
    });

    const optionLabel = safe(() => optionObject['labelKey']);
    const optionName = safe(() => optionObject['name']);

    return optionLabel ? intl.formatMessage({ id: optionLabel }) : optionName || value;
  } else if (filterType === 'date') {
    return getAppFormattedDate(value);
  } else if (value === true) {
    return intl.formatMessage({ id: 'feedbacks_tableView_label_managed_true' });
  } else if (value === false) {
    return intl.formatMessage({ id: 'feedbacks_tableView_label_managed_false' });
  } else return value;
}

export function getLabelFromOptions(options, toFindOption, fallback) {
  const predicate = (option = {}) => option.value === toFindOption;
  const found = _find(options, predicate);

  if (found) {
    if (found.labelKey) return <FormattedMessage id={found.labelKey} />;
    if (found.label) return found.label;
  } else return fallback;
}

export function getBookingColor(status) {
  if (status === BOOKING_STATUS_SCHEDULED) {
    return '#F78C35';
  }
  if (status === BOOKING_STATUS_IN_PROGRESS) {
    return '#29ADBF';
  }
  if (status === BOOKING_STATUS_COMPLETED) {
    return '#54AD59';
  }
  if (status === BOOKING_STATUS_PRE_BOOKED) {
    return '#ffcc32';
  }
}

export function hookTimesData(s, options) {
  if ([BOOKING_STATUS_SCHEDULED, BOOKING_STATUS_PRE_BOOKED].includes(s)) return options.creationDate;
  if ([BOOKING_STATUS_COMPLETED, STATUS_CANCELED].includes(s)) return options.effectiveEnd;
  if (s === BOOKING_STATUS_IN_PROGRESS) return options.effectiveStart;
}

export function enhanceSiteList(sitesList) {
  return sitesList.map(item => {
    return {
      ...item,
      label: item.name,
      _isSite: true
    };
  });
}

export function createInitVehiclePlanning(bookingDetail, changeVehicle = false) {
  return {
    memberTypeId: _get(bookingDetail, 'memberType.id'),
    from: {
      formattedAddress: _get(bookingDetail, 'start.parking.name', _get(bookingDetail, 'start.address.formattedAddress', '')),
      coordinates: _get(bookingDetail, 'start.coordinates', ''),
      _isSite: !!_get(bookingDetail, 'start.parking.name'),
      address: _get(bookingDetail, 'start.address', ''),
      id: changeVehicle ? _get(bookingDetail, 'start.parking.site.id', '') : _get(bookingDetail, 'start.parking.id', '')
    },
    to: {
      formattedAddress: _get(bookingDetail, 'end.parking.name', _get(bookingDetail, 'start.end.formattedAddress', '')),
      coordinates: _get(bookingDetail, 'end.coordinates', ''),
      _isSite: !!_get(bookingDetail, 'end.parking.name'),
      address: _get(bookingDetail, 'end.address', ''),
      id: changeVehicle ? _get(bookingDetail, 'end.parking.site.id', '') : _get(bookingDetail, 'end.parking.id', '')
    },
    tripType:
      _get(bookingDetail, 'start.address.formattedAddress', '') === _get(bookingDetail, 'end.address.formattedAddress', '')
        ? ROUND_TRIP
        : ONE_WAY_TRIP,
    pickupDate: _get(bookingDetail, 'start.date') ? moment(bookingDetail.start.date).toDate() : '',
    returnDate: _get(bookingDetail, 'end.date') ? moment(bookingDetail.end.date).toDate() : '',
    usageOverride: _get(bookingDetail, 'vehicleUsageAtBookingCreation'),
    pickupDateHour: _get(bookingDetail, 'start.date')
      ? moment(bookingDetail.start.date).hour() === 0
        ? '00'
        : moment(bookingDetail.start.date).format('HH')
      : '',
    returnDateHour: _get(bookingDetail, 'end.date')
      ? moment(bookingDetail.end.date).hour() === 0
        ? '00'
        : moment(bookingDetail.end.date).format('HH')
      : '',
    pickupDateMin: _get(bookingDetail, 'start.date')
      ? moment(bookingDetail.start.date).minute() === 0
        ? '00'
        : moment(bookingDetail.start.date).format('mm')
      : '00',
    returnDateMin: _get(bookingDetail, 'end.date')
      ? moment(bookingDetail.end.date).minute() === 0
        ? '00'
        : moment(bookingDetail.end.date).format('mm')
      : '00'
  };
}

export function converTimeValueToDoubleDigit(time) {
  if (time < 10) {
    time = time !== undefined ? time.toString() : '00';
    return '0' + time;
  }
  return time !== undefined ? time.toString() : '00';
}

export function createInitValuesCreateBooking(parking, firstDay, secondDay, startDate) {
  return {
    from: {
      formattedAddress: parking.name,
      _isSite: true,
      address: {
        id: parking.id
      }
    },
    to: {
      formattedAddress: parking.name,
      _isSite: true,
      address: {
        id: parking.id
      }
    },
    pickupDate: moment(startDate)
      .add(firstDay.value, 'days')
      .toDate(),
    returnDate: moment(startDate)
      .add(secondDay.value, 'days')
      .toDate(),
    pickupDateHour: '08',
    returnDateHour: '20'
  };
}

export function createInitValuesCreateBookingHourMode(parking, firstHour, secondHour, startDate) {
  return {
    from: {
      formattedAddress: parking.name,
      _isSite: true,
      address: {
        id: parking.id
      }
    },
    to: {
      formattedAddress: parking.name,
      _isSite: true,
      address: {
        id: parking.id
      }
    },
    pickupDate: moment(startDate).toDate(),
    returnDate: moment(startDate).toDate(),
    pickupDateHour: converTimeValueToDoubleDigit(firstHour.value),
    returnDateHour: converTimeValueToDoubleDigit(firstHour.value === secondHour.value ? secondHour.value + 1 : secondHour.value)
  };
}

export function createSharedInitValuesCreateBooking(state) {
  const {
    vehiclePlanning: { selectedMemberType, isPreBookingCreation, firstHourSelected, firstDaySelected },
    memberTypes: { list },
    members: { detailMember }
  } = state;
  const currentContract = headerCompanyContractSelector(state);
  const firstData = _isEmpty(firstHourSelected) ? firstDaySelected : firstHourSelected;
  const isBusinessCarSharing = _get(currentContract, 'businessCarSharing');
  const usageType = isBusinessCarSharing ? BOOKING_USAGE_TYPE_BUSINESS : BOOKING_USAGE_TYPE_PRIVATE;
  const memberTypeId = selectedMemberType || safe(() => detailMember.memberType.id) || list[0];
  const { vehicle } = firstData || {};
  const { usage: usageOverride } = vehicle || {};
  const { interfaceConfigDto } = currentContract || {};
  const { oneWayDisplay, returnTripDisplay } = interfaceConfigDto || {};
  const oneWay = oneWayDisplay && !returnTripDisplay;

  return {
    usageType,
    memberTypeId,
    replacementVehicle: !!isPreBookingCreation,
    usageOverride,
    pickupDateMin: '00',
    returnDateMin: '00',
    tripType: oneWay ? ONE_WAY_TRIP : ROUND_TRIP,
    paidBooking: PAID_BOOKING_INIT_VALUE,
    comment: BOOKING_COMMENT_INIT_VALUE
  };
}

export function createCompaniesOptions(initialOptions, resultOptions) {
  return _compact(
    initialOptions.map(({ name: label, id: value }) => {
      if (resultOptions.indexOf(value) !== -1) {
        return {
          label,
          value
        };
      }
    })
  );
}

export function createSubCompaniesOptions(initialOptions, resultOptions) {
  return _compact(
    initialOptions.map(({ label, value }) => {
      if (resultOptions.indexOf(value) !== -1) {
        return {
          label,
          value
        };
      }
    })
  );
}

export const getId = (item = {}) => item.id;
export const elemFound = (elems = [], elem) => elems.indexOf(elem) !== -1;

// add container to JSON property / if all container properties isEmpty return udnefined / deletes empty properties / shallow check
// todo: remove mutation of collection
export function append(collection) {
  const isArray = _isArray(collection);
  let index = isArray ? collection.length - 1 : 0;

  _forEachRight(collection, (value, key) => {
    if (isEmpty(value)) isArray ? collection.splice(index, 1) : delete collection[key];
    --index;
  });
  return _isEmpty(collection) ? undefined : collection;
}

// works like lodash _.set except it will not create path if value is empty
export function trySet(object, path, value, defaultValue) {
  const emptyValue = nulify(value) === undefined;
  if (emptyValue) {
    const emptyDefault = defaultValue === undefined;
    if (emptyDefault) return object;
    value = defaultValue;
  }
  return _set(object, path, value);
}

// get first obj prop name
export function getVarName(valueInObject) {
  return Object.keys(valueInObject)[0];
}

// works like trySet except it will use value name as path
export function quickSet(object, valueInObject, defaultValue) {
  const path = getVarName(valueInObject);
  const value = valueInObject[path];
  return trySet(object, path, value, defaultValue);
}

// run parser on valueInObject
export function valToObj(parser, valueInObject) {
  const path = getVarName(valueInObject);
  const value = valueInObject[path];
  valueInObject[path] = parser(value);
  return valueInObject;
}

export function objectToMomentFactory({ getDate, getHour, getMinute }) {
  return function objectToMoment(obj) {
    const date = getDate(obj);
    const hour = +getHour(obj);
    const minute = +getMinute(obj);

    return (
      date &&
      hour > -1 &&
      minute > -1 &&
      moment(date)
        .hour(hour)
        .minute(minute)
    );
  };
}

export const addZeroForSelect = value => (value < 10 ? '0' + value : value);

// return passed value or object | will return undefined if 'any' is empty
export function nulify(any) {
  let checker = isEmpty;

  if (_isObject(any)) {
    if (_isDate(any)) checker = isNaN;
    else checker = _isEmpty;
  }

  return checker(any) ? undefined : any;
}

// works like lodash _pick except it will pick only valid values (shallow)
export function pickValidValues(sourceObject, values) {
  return _pickBy(sourceObject, (value, key) => {
    const includes = _isArray(values) ? _includes(values, key) : values === key;
    return includes && nulify(value);
  });
}

// works like pickValidValues except it wrap value in array brackets
export function pickArrayValues(sourceObject, values) {
  return _mapValues(pickValidValues(sourceObject, values), value => (_isArray(value) ? value : [value]));
}

// compose several HOC | add display name to resulting component
export const namedCompose = (...hocs) => BaseComponent =>
  setDisplayName(wrapDisplayName(BaseComponent, 'Compose'))(compose(...hocs)(BaseComponent));

// check if new props received | path can be String or Array of Strings
export function newProps(aProps, bProps, bypass = false, path) {
  if (bypass) return true;
  if (_isArray(path)) {
    let result = false;
    _forEach(path, p => {
      if (newProps(aProps, bProps, false, p)) {
        result = true; // set result
        return false; // break loop
      }
    });
    return result;
  } else return !_isEqual(_get(aProps, path), _get(bProps, path));
}

// use .call | set 'propsInit' after first .call
export function newProp(props, prop) {
  const { [prop]: next } = props;
  const { [prop]: prev } = this.props;
  return next !== prev || !this.propsInit;
}

// improved version of 'newProps'
// use .call | set 'propsInit' after first .call
export function testProp(otherProps, testProps) {
  for (let prop in testProps) {
    const { [prop]: one } = otherProps;
    const { [prop]: two } = this.props;

    if (one !== two || !this.propsInit) {
      return true;
    }
  }
}

// excludeFromTrim - String (field name) or Array of Strings | function will mutate object
export function trimFields(fields, excludeFromTrim) {
  function notExcluded(field, key) {
    let toTrim;
    if (_isArray(excludeFromTrim)) toTrim = !_includes(excludeFromTrim, key);
    else toTrim = key !== excludeFromTrim;
    if (_isString(field)) return toTrim;
    else if (toTrim) trimFields(field);
  }

  if (_isObject(fields)) {
    _forEach(fields, (field, key) => {
      if (notExcluded(field, key)) fields[key] = field.trim();
    });
  }

  return fields;
}

// excludeFromTrim - String (field name) or Array of Strings
export function trimValues(form, excludeFromTrim) {
  return trimFields(getValues(form), excludeFromTrim);
}

function scrollToError() {
  safe(() => {
    document.getElementsByClassName('fieldErrorMsg')[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  });
}

export function scrollToFirstError() {
  try {
    setTimeout(scrollToError, 100);
  } catch (e) {
    console.warn('scrollToFirstError: failed', e); // eslint-disable-line no-console
  }
}

function scrollToModalError() {
  const body = j('div#box');
  const msg = j('.fieldErrorMsg');
  if (body && msg) {
    const offset = msg.offset();
    if (offset && offset.top > 250) {
      const animationProps = { scrollTop: offset.top - (offset.top - 250) };
      body.animate(animationProps, 500);
    }
  }
}

export function scrollToFirstErrorDialog() {
  try {
    setTimeout(scrollToModalError, 100);
  } catch (e) {
    console.warn('scrollToFirstError: failed', e); // eslint-disable-line no-console
  }
}

export function userCompanyType(role) {
  if (role === BACKUSER_ROLE_SUPER_ADMIN || role === BACKUSER_ROLE_ROOT) return;
  const subCompaniesRules = merge(subCompanyListRules, subCompanySelectRules);
  if (checkRole(subCompaniesRules, role)) return COMPANY_TYPE_SUB;
  else return COMPANY_TYPE_SUPER;
}

// https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer
export function base64ToArrayBuffer(base64) {
  if (!_isString(base64)) return false;
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) bytes[i] = binaryString.charCodeAt(i);
  return bytes.buffer;
}

export function isInternetExplorer11() {
  return !!_get(window, 'MSInputMethodContext') && !!_get(document, 'documentMode');
}

export function floatPrecision(value, decimals) {
  return Number(Number(value).toFixed(decimals));
}

export function downloadBase64(imageObject) {
  const arrayBuffer = base64ToArrayBuffer(_get(imageObject, 'content'));
  const fileType = _get(imageObject, 'mimeType', '');
  const mimeExtension = !!fileType ? mime.extension(fileType) : mime.extension(base64ToMimeType(_get(imageObject, 'content')));
  const fileBlob = new Blob([arrayBuffer], { type: fileType });
  let fileName = _get(imageObject, 'name', 'file');
  const extensionIndex = fileName.lastIndexOf('.');

  if (mimeExtension) {
    if (extensionIndex > 0) fileName = fileName.substr(0, extensionIndex);
    fileName += '.' + mimeExtension;
  }

  saveAs(fileBlob, fileName);
}

export function downloadImage(imageUrl, filename = '') {
  if (!imageUrl) return;
  return fetch(imageUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
      }
      return response.blob();
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);

      // If filename is not passed try to parse it from the imageUrl
      let downloadFilename = filename;
      if (!downloadFilename) {
        const urlParts = imageUrl.split('/');
        downloadFilename = urlParts[urlParts.length - 1];
      }

      const a = document.createElement('a');
      a.href = url;
      a.download = downloadFilename || 'downloaded-image'; // Set the filename
      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch(error => {
      console.error('Error downloading image:', error);
    });
}

export function daysListOfDatesRange(startDate, stopDate) {
  let dateArray = [];
  let currentDate = moment(startDate);
  stopDate = moment(stopDate).subtract(1, 'days');
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

// used for API requests | will mutate object
export function toLocalDate(object, path, format = 'YYYY-MM-DD') {
  const value = _get(object, path);
  const date = getLocalDate(value, format);
  if (date) _set(object, path, date);
}

// used for API requests
export function getLocalDate(value, format = 'YYYY-MM-DD') {
  if (value) {
    const momentDate = moment(value);
    if (momentDate.isValid()) return momentDate.local().format(format);
  }
}

export function valueToString(value, defaultValue = '') {
  return isEmpty(value) ? defaultValue : String(value);
}

export function boolToStringStrict(value) {
  if (value === true) return 'true';
  else if (value === false) return 'false';
}

export function boolToString(value) {
  return value ? 'true' : 'false';
}

export function arrayToString(arr) {
  return arr.toString();
}

export function intlMsg(that, key) {
  return that.props.intl.formatMessage({ id: key, defaultMessage: key });
}

export function radioValueTrue(value) {
  return value === 'true';
}

export function radioFieldEnabled(bool = {}) {
  return radioValueTrue(bool.value);
}

export function fieldEnabled(field) {
  return typeof field === 'string' ? field === 'true' : field;
}

export function streetNameFormat(number = '', name = '') {
  return number + (number ? ' ' : '') + name;
}

// full 'function' name syntax is required here
export function callbackNot(func) {
  function callback() {
    return !func.apply(this, arguments);
  }

  return callback;
}

export function getFieldValue(props, values, field) {
  const initialValues = _get(props, 'initialValues');
  const value = _get(values, field);
  return isEmpty(value) ? _get(initialValues, field) : value;
}

function checkPartialError(partialError, fullMessage) {
  const { partialErrorMsg, translationKey } = partialError;
  if (_includes(fullMessage, partialErrorMsg)) {
    return translationKey;
  }
}

function checkPartialErrors(partialErrors, fullMessage) {
  let key = undefined;

  if (partialErrors && fullMessage) {
    _forEach(partialErrors, error => {
      const resp = checkPartialError(error, fullMessage);
      if (resp) {
        key = resp;
        return false; // exit loop manually
      }
    });
  }

  return key;
}

function checkValidationErrors(partialErrors, validationErrors) {
  let key = undefined;

  if (validationErrors) {
    _forEach(validationErrors, validationError => {
      const staticKey = errorCodes[validationError];

      if (staticKey) {
        key = staticKey;
        return false; // exit loop manually
      }

      const partialKey = checkPartialErrors(partialErrors, validationError);

      if (partialKey) {
        key = partialKey;
        return false; // exit loop manually
      }
    });
  }

  return key;
}

function getErrorFromBundle(bundle, code, errorCodePrefixes) {
  let msg = undefined;

  if (errorCodePrefixes) {
    _forEach(errorCodePrefixes, prefix => {
      const key = prefix + code;
      msg = bundle[key];
      if (msg) return false; // exit loop manually
    });
  }

  return msg || bundle[code];
}

/*
  error - error returned as response
  bundle - translation bundle
  partialErrors - array of partial errors
  def - default error message
  errorCodePrefixes - array of errorCode prefixes
  */
export function getErrorMsg({ error, bundle, partialErrors, errorCodePrefixes, def = 'error_server_unknown' }) {
  if (bundle) {
    if (error) {
      const { developerMessage, errorCode, validationErrors } = error;
      const firstValidationError = validationErrors ? validationErrors[0] : undefined;

      const code =
        errorCodes[errorCode] ||
        errorCode ||
        errorCodes[developerMessage] ||
        checkPartialErrors(partialErrors, developerMessage) ||
        checkValidationErrors(partialErrors, validationErrors);

      return (
        getErrorFromBundle(bundle, code, errorCodePrefixes) ||
        developerMessage ||
        firstValidationError ||
        errorCode ||
        code ||
        bundle[def] ||
        def
      );
    }

    return bundle[def];
  }

  return def;
}

/*
  read addErrorMessage message
  contentData - addFlashMessage contentData param
  */
export function addErrorMessage({ msgPrefix = '', error, bundle, partialErrors, errorCodePrefixes, def, contentData }) {
  const translations = bundle || bundleSelector(getStore().getState());

  const params = {
    content: msgPrefix + getErrorMsg({ error, bundle: translations, partialErrors, errorCodePrefixes, def }),
    type: FLASH_MESSAGE_TYPE_ERROR
  };

  if (contentData) params.contentData = contentData;
  return addFlashMessage(params);
}

export function getKey(base = {}, key, def = 'common_unknown') {
  return base[key] || def;
}

export function setIfString(where, path, what) {
  if (_isString(what)) return _set(where, path, what);
}

export function isValidId(id) {
  return regexGuid.test(id);
}

export function hideDriverImagesForCustomCompanyId(companyId, status) {
  return status !== STATUS_TO_REVIEW && configConstants.hideDriverImageForIds[companyId];
}

export function hideVehiclePositionForCustomCompanyId(companyId, userRole) {
  return userRole === BACKUSER_ROLE_ADMIN && configConstants.hideGPSCoordForIds[companyId];
}

export function toBoolean(value) {
  if (typeof value === 'string') return value === 'true';
  return !!value;
}

export function toNumber(value) {
  const num = Number(value);
  if (Number.isNaN(num)) return undefined;
  return num;
}

// this method will mutate object
export function setApiCompanyIds(obj, state, { setSubCompanyId = true, appendBoth = true } = {}) {
  const singleCompanyId = selectCompanyId(state);
  const userCompanyIds = userCompanyIdsSelector(state);
  const companyIds = append([singleCompanyId]) || append(userCompanyIds);

  let subCompanyIds;

  if (setSubCompanyId && companyIds) {
    const singleSubCompanyId = selectSubCompanyId(state);
    const userSubCompanyIds = userHeaderSubCompanyIdsSelector(state);
    const userRole = userRoleSelector(state);
    const limitToUserSubCompanies = _includes(renaultItalyRoles, userRole);

    subCompanyIds = append([singleSubCompanyId]);
    if (limitToUserSubCompanies) subCompanyIds = subCompanyIds || append(userSubCompanyIds);
  }

  trySet(obj, 'subCompanyIds', subCompanyIds);

  if (appendBoth || !obj.subCompanyIds) {
    trySet(obj, 'companyIds', companyIds);
  }
}

export function formatMonetaryAmount(value = 0, currencyCode) {
  const currencySymbol = configConstants.currencySymbol[currencyCode] || currencyCode;
  return currencySymbol ? value + ' ' + currencySymbol : value;
}

export const getFileNameFromUrl = _memoize((commissionInvoiceUrl = '') => {
  const fileName = commissionInvoiceUrl
    .split('/')
    .pop()
    .split('.');

  if (fileName.length > 1) fileName.pop();
  return fileName.join('.');
});

export function jsonParseSafe(text) {
  try {
    return JSON.parse(text);
  } catch (e) {
    return '';
  }
}

export function cleanCompanyApiParams(obj) {
  return cleanDeep(obj, { cleanValues: ['-', ALL, URL_ALL] });
}

export function guessTimeZone() {
  // noinspection JSUnresolvedVariable
  return momentTz.tz.guess();
}

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export function formPost(path, params, method = 'post') {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export function fieldValueSafe(field = {}) {
  const { initialValue, value } = field;
  return value || initialValue;
}

export function fieldValue(field = {}) {
  return field.value;
}

// must be called with .call(this, field)
export function valueFirstInit(field) {
  if (!this.initDone) {
    const { initialValue, value, onChange } = field || {};
    if (!isEmpty(value)) this.initDone = true;
    if (!this.initDone && !isEmpty(initialValue) && value !== initialValue) {
      if (onChange) {
        onChange(initialValue);
        this.initDone = true;
      }
    }
  }
}

// 'this' must be provided
export function vpChangeMemberType(memberTypeId) {
  const {
    fields: { memberTypeId: memberTypeField },
    selectedMemberId,
    dispatch,
    bookingDetail,
    firstHourSelected,
    firstDaySelected,
    VehiclePlanningCreateBooking
  } = this.props;
  const payload = {
    memberId: selectedMemberId,
    memberTypeId
  };

  const usageType = _get(bookingDetail, 'carSharingInfo.usageType') || _get(VehiclePlanningCreateBooking, 'usageType.value');
  const vehicleId = _get(bookingDetail, 'vehicle.id') || _get(firstHourSelected, 'vehicle.id') || _get(firstDaySelected, 'vehicle.id');
  this.setState({ memberTypeSelectDisabled: true });
  const fieldDisabledDelay = 500;
  const callStartTime = new Date();

  const enableField = () => {
    const callEndTime = new Date();
    const elapsed = callEndTime - callStartTime;
    const timeout = fieldDisabledDelay < elapsed ? 0 : elapsed;

    setTimeout(() => this.setState({ memberTypeSelectDisabled: false }), timeout);

    if (usageType === BOOKING_USAGE_TYPE_PRIVATE) {
      dispatch(getEstimatedPriceBooking()).then(data => {
        const item = _find(data.results, { vehicle: { id: vehicleId } });
        if (data.results.length === 0 && !item) {
          dispatch(displayBookingPrice(false));
        } else {
          dispatch(displayBookingPrice(item));
        }
      });
    }
  };

  const handleError = () => {
    memberTypeField.onChange(memberTypeId);
    enableField();
  };

  dispatch(requestMemberTypeChange(payload)).then(enableField, handleError);
}

export function getMaterialLocale(locale) {
  return MATERIAL_LOCALE_MAP[locale] || locale;
}

export function addTippyContent(html) {
  if (typeof html === 'string') return { title: html };
  else return { html };
}

export function vehicleUsageSwitch(usage) {
  return usage === VEHICLE_USAGE_TYPE_RV || usage === VEHICLE_USAGE_TYPE_TEST_DRIVE;
}

export function vehicleUsageFiltered(usage) {
  return (
    usage === VEHICLE_USAGE_TYPE_RV ||
    usage === VEHICLE_USAGE_TYPE_TEST_DRIVE ||
    usage === VEHICLE_USAGE_TYPE_PUBLIC_SERVICE ||
    usage === VEHICLE_USAGE_TYPE_DELIVERY
  );
}

export function urlRemoveDoubleSlash(url = '') {
  return url.replace(/([^:])(\/\/+)/g, '$1');
}

export function safe(f) {
  try {
    return f();
  } catch (e) {}
}

export function getLastItem(array) {
  return safe(() => array[array.length - 1]);
}

export function getExtFromFileName(name) {
  const base64data = safe(() => name.substr(0, name.indexOf(';')));
  const isBase64d = safe(() => base64data.substring(0, 5) === 'data:');

  if (isBase64d) {
    name = base64data.replace('/', '.');
  }

  return safe(() =>
    name
      .split('.')
      .pop()
      .toLowerCase()
  );
}

export const fallbackFunc = () => undefined;
export const allowFunc = () => true;
export const fallbackObj = Object.freeze({});
export const fallbackArray = Object.freeze([]);

export function showErrorInfo({ meta }) {
  const { touched, error } = meta || {};
  const isError = touched && error;
  const errorType = typeof error === 'string' ? 'text' : 'border';

  if (isError) {
    return <div className={classnames('field-error-info', errorType)}>{error}</div>;
  }
}

export function setStorageData(key, data) {
  safe(() => localStorage.setItem(key, JSON.stringify(data)));
}

export function getStorageData(key) {
  return safe(() => JSON.parse(localStorage.getItem(key)));
}

const viewportContent = mobile => {
  return `width=${mobile ? window.screen.width : '1300'}`;
};

function addViewportTag(mobile) {
  const meta = document.createElement('meta');

  meta.name = 'viewport';
  meta.id = STORAGE_KEY.MOBILE_VIEW;
  meta.content = viewportContent(mobile);

  document.getElementsByTagName('head')[0].appendChild(meta);
}

export function updateMobileViewTag(status = getStorageData(STORAGE_KEY.MOBILE_VIEW)) {
  const el = document.getElementById(STORAGE_KEY.MOBILE_VIEW);

  if (status) {
    if (el) el.content = viewportContent(true);
    else addViewportTag(true);
  } else {
    if (el) el.content = viewportContent(false);
    else addViewportTag(false);
  }
}

export function isLocalEnv() {
  return config.debugMode;
}

export function isTestEnv() {
  return config.devMode;
}

export function getMainColor() {
  const color = safe(() => brandThemeSelector(getStore().getState()).color);
  if (color) return color;
}

export function addStrPart(part, sep = ', ') {
  return part ? part + sep : '';
}

export function getFileExt(fileName) {
  if (fileName) {
    const extIndex = fileName.lastIndexOf('.');
    if (extIndex > 0) return fileName.substr(extIndex + 1);
  }
}

export function getApiUrl() {
  const { apiUrl } = getAppObj();
  if (apiUrl) return apiUrl;

  const sessionApiUrl = safe(() => window.sessionStorage.getItem(STORAGE_KEY.API_URL));
  if (sessionApiUrl) return sessionApiUrl;

  return config.apiDefaultUrl;
}

const getEnvFromUrl = () => {
  let apiEnv = safe(() => getApiUrl().split('-')[1]);
  if (!validEnvs.includes(apiEnv)) apiEnv = 'prod';
  return (getAppObj().env = apiEnv);
};

export const getCurrentEnv = () => {
  if (!config.devMode) return 'prod';
  return getAppObj().env || getEnvFromUrl();
};

export const getWebApiUrl = (urlBrand = 'glide') => {
  const apiEnv = getCurrentEnv();
  return `https://gwweb-${apiEnv}-${urlBrand}.tech.rcimobility.com/api`;
};

export function setBase64({ mimeType, content } = {}) {
  if (mimeType && content) {
    return `data:${mimeType};base64,${content}`;
  }
}

export function getAppObj() {
  return safe(() => window.APP) || {};
}

export function removeMultiSpace(str) {
  return str.replace(regexMultiSpace, ' ').trim();
}

// do not add default values to params (using memoizeOne)
const createCompanyUrlsByDomain = memoizeOne((domainFront, domainBack) => {
  const backResetPath = routes.resetPassword.path.replace(':resetPasswordToken', '{resetPasswordToken}');

  return {
    frontConfirmSubscription: `https://${domainFront}/#/validate-account/{confirmSubscriptionToken}`,
    frontResetPassword: `https://${domainFront}/#/reset-password/{resetPasswordToken}`,
    backResetPassword: `https://${domainBack}/#${backResetPath}`
  };
});

const getLocalDomains = (env, theme) => {
  const foUrlBrand = themeToLocalFoUrlBrandMap[theme];
  const boUrlBrand = themeToLocalBoUrlBrandMap[theme];

  if (env && boUrlBrand && boUrlBrand)
    return {
      front: `web-${env}-${boUrlBrand}.tech.rcimobility.com`,
      back: `admin-${env}-${foUrlBrand}.tech.rcimobility.com`
    };
};

// do not add default values to params (using memoizeOne)
export const getThemeDomains = memoizeOne((env, theme) => {
  if (localEnvs.includes(env)) {
    return getLocalDomains(env, theme);
  }
  return rootConfig.themesDomain[theme];
});

export function createCompanyDevUrls(domains = safe(() => getThemeDomains(getCurrentEnv(), themeConstants.currentTheme))) {
  if (domains) return createCompanyUrlsByDomain(domains.front, domains.back);
}

export function vehicleAapter(vehicle) {
  let retVehicle = {};
  retVehicle = { ...vehicle };

  const usage = _get(vehicle, 'usage');
  const picture = _get(vehicle, 'pictureUrl');
  const brand = _get(vehicle, 'version.model.brand.name');
  const model = _get(vehicle, 'version.model.name');
  const fuelLevel = _get(vehicle, 'fuelLevel.percentage');
  if (picture) retVehicle.picture = picture;
  if (usage) retVehicle.usage = usage;
  if (brand) retVehicle.brand = brand;
  if (model) retVehicle.model = model;
  if (fuelLevel) retVehicle.fuelLevel = fuelLevel;
  return retVehicle;
}

export function enhanceHomepageData(service) {
  const retData = { ...service };
  const serviceNames = _get(retData, 'title');
  const descriptions = _get(retData, 'description', []);
  const action = _get(retData, 'action');
  const btnTitles = _get(action, 'title');
  const picture = _get(retData, 'imageUrl');
  const options = _get(retData, 'options');
  let t = [];
  for (const [key, value] of Object.entries(serviceNames)) {
    t.push({ language: `${key}`, translation: `${value}` });
  }

  let d = [];
  if (descriptions)
    for (const [key, value] of Object.entries(descriptions)) {
      d.push({ language: `${key}`, translation: `${value}` });
    }

  let b = [];
  if (btnTitles)
    for (const [key, value] of Object.entries(btnTitles)) {
      b.push({ language: `${key}`, translation: `${value}` });
    }

  if (picture) retData.imageUrl = picture;
  if (serviceNames) retData.serviceNames = t;
  if (descriptions) retData.descriptions = d;
  if (action) retData.action = { ...action };
  if (btnTitles) retData.action.title = b;
  if (options) retData.options = { ...options };

  return retData;
}

export function i18nMobileKeys(arr) {
  let newProps = {};
  arr.map(field => {
    newProps = { ...newProps, [field.language]: field.translation };
  });
  return newProps;
}

export const i18nAdditionnalDocTitleKeys = _memoize(arr => {
  if (arr && arr.length) {
    return arr.map(item => {
      return { language: item.language, label: item.translation };
    });
  }
  return [];
});

export const i18nAdditionnalDocFormKeys = _memoize(arr => {
  if (arr && arr.length) {
    return arr.map(item => {
      return { language: item.language, translation: item.label };
    });
  }
  return [];
});

export function mapOrder(a, order, key) {
  const map = order.reduce((r, v, i) => ((r[v] = i), r), {});
  return a.sort((a, b) => map[a[key]] - map[b[key]]);
}

export function humanShortDuration(durationInMinutes, locale, intl, spacer = '') {
  const delay = humanize(durationInMinutes, {
    language: locale,
    spacer,
    conjunction: ' ' + intl.formatMessage({ id: 'booking_delay_separator_and' }) + ' ',
    serialComma: false,
    languages: {
      [locale]: {
        y: () => intl.formatMessage({ id: 'booking_delay_duration_short_year' }),
        mo: () => intl.formatMessage({ id: 'booking_delay_duration_short_month' }),
        w: () => intl.formatMessage({ id: 'booking_delay_duration_short_week' }),
        d: () => intl.formatMessage({ id: 'booking_delay_duration_short_day' }),
        h: () => intl.formatMessage({ id: 'booking_delay_duration_short_hour' }),
        m: () => intl.formatMessage({ id: 'booking_delay_duration_short_minute' }),
        s: () => intl.formatMessage({ id: 'booking_delay_duration_short_second' })
      }
    }
  });
  return delay;
}

export function hasStartBeforeCancel(statuses = []) {
  if (statuses.length) {
    const inProgressEvent = _find(statuses, { type: BOOKING_STATUS_IN_PROGRESS });
    const canceledEvent = _find(statuses, { type: STATUS_CANCELED });
    if (inProgressEvent && canceledEvent) return canceledEvent;
    else return { type: 'fake' };
  }
}

export const round2decimal = num => Math.round(num * 100) / 100;
export const round1decimal = num => Math.round(num * 10) / 10;

export const getPercentDiff = (diff, from) => {
  let result = (diff / from) * 100 || 100;
  if (result === Infinity) result = 100;
  return result;
};

export const getUriObj = memoizeOne(uri => {
  const obj = JSON.parse(decodeURIComponent(uri));
  if (typeof obj === 'object') return obj;
});

export const getFormValues = formName => {
  return safe(() => cleanDeep(trimValues(getStore().getState().form[formName])));
};

export const hasValues = obj => safe(() => Object.keys(obj).length);

export const fuelLevelCap100 = value => {
  if (value > 100) return 100;
  return value;
};

function getOperatingSystem(window) {
  let operatingSystem = OS_UNKNOWN;
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = OS_WINDOWS;
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = OS_MAC;
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = OS_UNIX;
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = OS_LINUX;
  }
  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Google Chrome';
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox';
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Exployer';
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge';
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'YaBrowser';
  }

  return currentBrowser;
}

export const OS = window => {
  return getOperatingSystem(window);
};

export const currentBrowser = window => {
  return getBrowser(window);
};

export const isObjEmpty = obj => {
  for (const i in obj || {}) return false;
  return true;
};

export const getFirstObjKey = (obj = {}) => {
  for (const i in obj) return i;
};

export const getFirstObjValue = (obj = {}) => {
  for (const i in obj) return obj[i];
};

export const getCompanyNameUrl = (name, id) => {
  const granted = isGranted({ disallowed: companyRules.exclude, allowed: companyRules.include });

  if (id && granted) {
    return (
      <a title={getMsg('common_super_company')} href={`#${routes.companyDetail.path.replace(':companyId', id)}`}>
        {name}
      </a>
    );
  }
  return name;
};

export const getSubCompanyNameUrl = (name, id) => {
  const granted = isGranted({ disallowed: subCompanyRules.exclude, allowed: subCompanyRules.include });

  if (id && granted) {
    return (
      <a title={getMsg('common_company')} href={`#${routes.subCompanyDetails.path.replace(':subCompanyId', id)}`}>
        {name}
      </a>
    );
  }
  return name;
};

export const getCurrencySym = sym => {
  return getMsg('unit_' + sym, { def: '' });
};

export const withCol = id => {
  return getMsg('data.with.colon', { values: { data: getMsg(id) } });
};

export const shortenBookingId = data => {
  const { bookingId } = data || {};
  if (bookingId) return { ...data, bookingId: getShortId(bookingId) };
  return data;
};

export function getBookingInfo(bookingData, path) {
  const { carSharingInfo, groupSharingInfo, rrsSharingInfo } = bookingData || {};
  let info = _get(carSharingInfo, path);
  if (info === undefined) info = _get(groupSharingInfo, path);
  if (info === undefined) info = _get(rrsSharingInfo, path);
  return info;
}

export function findNestedObj(obj, key, value) {
  try {
    JSON.stringify(obj, (_, nestedValue) => {
      if (nestedValue && nestedValue[key] === value) throw nestedValue;
      return nestedValue;
    });
  } catch (result) {
    return result;
  }
}

export const elementsOverlap = (el1, el2) => {
  const rect1 = safe(() => el1.getBoundingClientRect());
  const rect2 = safe(() => el2.getBoundingClientRect());

  return rect1.right > rect2.left && rect1.left < rect2.right && rect1.bottom > rect2.top && rect1.top < rect2.bottom;
};

export const showButtonHoverMsg = data => {
  const { buttonHoverRef } = getAppObj();

  safe(() => buttonHoverRef.classList.add('sc-show'));
  safe(() => buttonHoverRef.classList.remove('sc-overlap'));

  if (elementsOverlap(buttonHoverRef, data.target)) {
    safe(() => buttonHoverRef.classList.add('sc-overlap'));
  }
};

export const hideButtonHoverMsg = () => {
  safe(() => getAppObj().buttonHoverRef.classList.remove('sc-show'));
};

function middleMouseButtonPressed(e) {
  return e && e.button === 1;
}

function ctrlButtonPressed(e) {
  return e.ctrlKey || e.metaKey;
}

// use 'hideButtonHoverMsg' inside 'componentWillUnmount' for each component using 'addOpenNewTabEvents'
export function addOpenNewTabEvents(callback) {
  const invokeCallback = openInNewTab => callback(openInNewTab);

  const onClick = e => {
    invokeCallback(ctrlButtonPressed(e));
  };

  const onMouseDown = e => {
    if (middleMouseButtonPressed(e)) {
      invokeCallback(true);
    }
  };

  return { onMouseDown, onClick, onMouseEnter: showButtonHoverMsg, onMouseLeave: hideButtonHoverMsg };
}

export function openUrlBackgroundTab(href) {
  const a = document.createElement('a');
  a.href = href;
  a.target = '_blank';
  const e = new MouseEvent('click', {
    ctrlKey: true,
    metaKey: true
  });
  a.dispatchEvent(e);
}

export function strEqual(arr, str) {
  return safe(() => arr.find(el => el === str));
}

export function mapArrayIndexToObjectById(arr = []) {
  return arr.reduce((ret, next, index) => {
    const { id } = next || {};
    if (id) ret[id] = index;
    return ret;
  }, {});
}

export const debounce = (callback, wait) => {
  let timeoutId = null;

  const bouncer = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
  bouncer.cancel = () => clearTimeout(timeoutId);
  return bouncer;
};

export const isUpperCase = str => safe(() => str === str.toUpperCase());
