import moment from 'moment';
import _assign from 'lodash/assign';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _forEach from 'lodash/forEach';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { addFlashMessage, poolExport, uploadFileGetUrl, uploadFile } from './all-actions';

import {
  selectCompanyId,
  selectSubCompanyId,
  append,
  trySet,
  pickArrayValues,
  pickValidValues,
  trimValues,
  // downloadBase64,
  localToUTC,
  addErrorMessage,
  setApiCompanyIds,
  isEmpty,
  safe,
  downloadImage
} from '../utils/utils';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import {
  CLEANLINESS_STATUS_CLEAN,
  CLEANLINESS_STATUS_DIRTY,
  BACKUSER_ROLE_FIELD_AGENT,
  EXTERNAL_SYSTEM_LINKS
} from '../constants/backend-constants';
import { vehicleAccessories } from '../constants/options-constants';
import { redirectToRoute } from '../routing/utils-routing';
import routes from '../constants/routes-constants';
import { apiParams } from '../constants/api-params-constants';
import { bundleSelector } from '../selectors/all-selectors';

export function getFleetList(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const pageNumber = _get(params, 'page.number') || apiParams.default.page.number;
    const pageSize = _get(params, 'page.size') || apiParams.default.page.size;
    const paramsObj = {
      page: {
        number: pageNumber,
        size: pageSize
      }
    };

    setApiCompanyIds(paramsObj, state);

    _assign(
      paramsObj,
      _pick(params, [
        'transmissionTypes',
        'fuelTypes',
        'accessories',
        'brand',
        'lowFuelLevel',
        'model',
        'category',
        'color',
        'version',
        'usage',
        'type',
        'plateNumber',
        'cleanliness',
        'sort',
        'systemType',
        'vin',
        'telematicDeviceId',
        'maximumBoardVoltage',
        'minimumBoardVoltage',
        'vehicleStatus',
        'includeUninstalledDevice'
      ])
    );

    dispatch({ type: types.VEHICLES_GET_FLEET_REQUEST });

    return dispatch(callApi('vehicles', 'getFleet', { params: paramsObj }, loader))
      .then(function(data) {
        dispatch({
          type: types.VEHICLES_GET_FLEET_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
      })
      .catch(() => {
        dispatch({
          type: types.VEHICLES_GET_FLEET_ERROR
        });
      });
  };
}

export function clearFleetListData() {
  return {
    type: types.VEHICLES_CLEAR_LIST_DATA
  };
}

export function copyVehicle() {
  return {
    type: types.VEHICLE_COPY_TO_CREATE_NEW
  };
}

export function requestVehicleCreation({ company }) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      transmissionType,
      fuelType,
      carVersion,
      numberOfSeats,
      vehicleClass,
      vehicleColor,
      pictureData,
      vehicleOptions,
      vinNumber,
      licencePlate,
      registrationDate,
      registrationFile,
      usage,
      vehicleOwner,
      siteLocation,
      parkingLocation,
      serviceLevel,
      usedSystems,
      deviceSerialNumber,
      deviceInstallation,
      deviceRemoval,
      deviceWithKey,
      type,
      doorsNumber,
      description,
      proplannerId
    } = trimValues(state.form.vehicleForm);

    let accessories = [];
    vehicleAccessories.forEach((item, index) => {
      if (vehicleOptions[index]) {
        accessories.push(item.value);
      }
    });

    const params = {
      transmissionType,
      fuelType,
      carVersion,
      numberOfSeats,
      vehicleClass,
      vehicleColor,
      accessories,
      vinNumber,
      usage,
      licencePlate,
      registrationDate,
      vehicleOwner,
      siteLocation,
      parkingLocation,
      serviceLevel,
      usedSystems,
      deviceSerialNumber,
      deviceInstallation,
      deviceRemoval,
      deviceWithKey,
      company,
      type,
      doorsNumber,
      description
    };

    if (proplannerId) {
      params.vehicleLinks = [
        {
          vehicleExternalId: proplannerId,
          externalSystemName: EXTERNAL_SYSTEM_LINKS.PROPLANNER
        }
      ];
    } else {
      params.vehicleLinks = [];
    }

    const addRegisterDocument = () => {
      if (safe(() => registrationFile.rawFile)) {
        const props = {
          file: registrationFile.rawFile,
          mimeType: registrationFile.mimeType,
          isPublicFile: true
        };
        // return dispatch(callApi('file', 'createFileUploadUrl', { file: registrationFile.rawFile, isPublicUpload: false }))
        return dispatch(uploadFile(props))
          .then(fileId => {
            params.registrationDocumentId = fileId;
          })
          .catch(() => {
            dispatch(
              addFlashMessage({
                contentKey: 'vehicleForm_create_vehicle_error',
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
            return Promise.reject();
          });
      }
      return Promise.resolve();
    };

    const addVehiclePicture = () => {
      if (safe(() => pictureData.blob)) {
        return dispatch(uploadFileGetUrl({ file: pictureData.blob })).then(pictureUrl => {
          params.pictureUrl = pictureUrl;
        });
      } else {
        params.pictureUrl = pictureData.image;
      }
      return Promise.resolve();
    };

    const addVehicleRequest = () => {
      return dispatch(requestVehicleCreationApiCall(params));
    };

    dispatch({ type: types.VEHICLE_FORM_CREATE_NEW_VEHICLE_REQUEST, params });

    return dispatch(addVehiclePicture)
      .then(addRegisterDocument)
      .then(addVehicleRequest);
  };
}

function requestVehicleCreationApiCall(params) {
  return dispatch => {
    return dispatch(callApi('vehicles', 'createVehicle', params))
      .then(vehicle => {
        dispatch(setDeviceRemovalReminder(vehicle));
        dispatch(callCreateVehicleSuccess(vehicle));
        dispatch(
          addFlashMessage({
            contentKey: 'vehicleForm_create_vehicle_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        redirectToRoute(routes.vehicleDetail.path.replace(':vehicleId', vehicle.id));
      })
      .catch(error => {
        if (_get(error, 'errorCode')) {
          dispatch(
            addFlashMessage({
              contentKey: `vehicleForm_${error.errorCode}`,
              contentData: error.errorParams ? error.errorParams : {},
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'vehicleForm_create_vehicle_error',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
      });
  };
}

export function callCreateVehicleSuccess(vehicle) {
  return {
    type: types.VEHICLE_FORM_CREATE_NEW_VEHICLE_SUCCESS,
    vehicle
  };
}

export function setDeviceRemovalReminder(vehicle) {
  return {
    type: types.VEHICLE_SET_DEVICE_REMOVAL_REMINDER,
    vehicle
  };
}

export function requestVehicleUpdate() {
  return (dispatch, getState) => {
    const state = getState();
    const registrationDocumentId = state.vehicles.detailVehicle.registrationDocumentId;

    const {
      transmissionType,
      fuelType,
      carVersion,
      numberOfSeats,
      vehicleClass,
      vehicleColor,
      pictureData,
      vehicleOptions,
      vinNumber,
      licencePlate,
      usage,
      registrationDate,
      registrationFile,
      vehicleOwner,
      siteLocation,
      parkingLocation,
      usedSystems,
      deviceSerialNumber,
      deviceInstallation,
      deviceRemoval,
      type,
      doorsNumber,
      description,
      deviceWithKey,
      proplannerId
    } = trimValues(state.form.vehicleForm);

    let accessories = [];

    vehicleAccessories.forEach((item, index) => {
      if (vehicleOptions[index]) {
        accessories.push(item.value);
      }
    });

    let params = {
      vehicleId: state.vehicles.detailVehicle.id,
      company: state.vehicles.detailVehicle.company,
      transmissionType,
      usage,
      fuelType,
      carVersion,
      numberOfSeats,
      vehicleClass,
      vehicleColor,
      accessories,
      vinNumber,
      licencePlate,
      registrationDate,
      registrationDocumentId,
      vehicleOwner,
      siteLocation: siteLocation[0],
      parkingLocation: parkingLocation[0],
      usedSystems,
      deviceSerialNumber,
      deviceInstallation,
      deviceRemoval,
      type,
      doorsNumber,
      description,
      deviceWithKey
    };

    if (proplannerId)
      params.vehicleLinkUpdate = [
        {
          vehicleExternalId: proplannerId,
          externalSystemName: EXTERNAL_SYSTEM_LINKS.PROPLANNER
        }
      ];
    else {
      params.vehicleLinkUpdate = [];
    }

    const editRegisterDocument = () => {
      if (safe(() => registrationFile.rawFile)) {
        const props = {
          file: registrationFile.rawFile,
          mimeType: registrationFile.mimeType,
          isPublicFile: true
        };
        return dispatch(uploadFile(props))
          .then(fileId => {
            params.registrationDocumentId = fileId;
          })
          .catch(error => {
            dispatch(
              addFlashMessage({
                contentKey: 'vehicleForm_update_vehicle_error',
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
            return Promise.reject(error);
          });
      }
      return Promise.resolve();
    };

    const editVehiclePicture = () => {
      if (safe(() => pictureData.blob)) {
        return dispatch(uploadFileGetUrl({ file: pictureData.blob })).then(pictureUrl => {
          // console.log('success upload and get ', pictureUrl);
          params.pictureUrl = pictureUrl;
        });
      } else {
        params.pictureUrl = pictureData.image;
      }
      // console.log('payload params', params.pictureUrl);
      return Promise.resolve();
    };

    const requestEditVehicle = () => {
      return dispatch(callApi('vehicles', 'updateVehicle', params)).catch(error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      });
    };

    dispatch({ type: types.VEHICLE_FORM_UPDATE_VEHICLE_REQUEST, params });

    return dispatch(editVehiclePicture)
      .then(editRegisterDocument)
      .then(requestEditVehicle);
  };
}

export function callUpdateVehicleSuccess(vehicle) {
  return {
    type: types.VEHICLE_FORM_UPDATE_VEHICLE_SUCCESS,
    vehicle
  };
}

export function initCreateVehicleForm() {
  return {
    type: types.VEHICLE_FORM_CREATE_NEW_VEHICLE_INIT
  };
}

export function hideVehicleImagePreview() {
  return {
    type: types.VEHICLE_FORM_IMAGE_PREVIEW_HIDE
  };
}

export function getVehicleCategoriesList() {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_GET_CATEGORIES_LIST_REQUEST
    });

    return dispatch(callApi('vehicles', 'getCategoriesList')).then(function(data) {
      dispatch({
        type: types.VEHICLES_GET_CATEGORIES_LIST_SUCCESS,
        data: data
      });
      return data;
    });
  };
}

export function getVehicleDetail(id, loader = true) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_GET_DETAIL_VEHICLE_REQUEST
    });

    return dispatch(callApi('vehicles', 'getVehicleDetail', id, loader)).then(function(data) {
      dispatch({
        type: types.VEHICLES_GET_DETAIL_VEHICLE_SUCCESS,
        vehicle: data
      });
      return data;
    });
  };
}

export function toggleVehiclesFilters() {
  return {
    type: types.VEHICLES_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function openChangeStatusModal() {
  return {
    type: types.VEHICLES_OPEN_CHANGE_STATUS_MODAL
  };
}

export function openConfirmStatusModal() {
  return {
    type: types.VEHICLES_CONFIRM_ADD_STATUS_MODAL_OPEN
  };
}

export function closeConfirmStatusModal() {
  return {
    type: types.VEHICLES_CONFIRM_ADD_STATUS_MODAL_CLOSE
  };
}

export function closeChangeStatusModal() {
  return {
    type: types.VEHICLES_CLOSE_CHANGE_STATUS_MODAL
  };
}

export function openDeleteStatusModal(statusId) {
  return {
    type: types.VEHICLES_OPEN_DELETE_STATUS_MODAL,
    statusId
  };
}

export function closeDeleteStatusModal() {
  return {
    type: types.VEHICLES_CLOSE_DELETE_STATUS_MODAL
  };
}

export function getStatuses(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_GET_STATUSES_REQUEST
    });

    return dispatch(callApi('vehicles', 'getStatuses', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_GET_STATUSES_SUCCESS,
        statuses: data
      });
      return data;
    });
  };
}

export function setVehicleStatus(form, vehicleId) {
  return (dispatch, getState) => {
    function showSuccess() {
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_add_status_success',
          type: FLASH_MESSAGE_TYPE_SUCCESS
        })
      );
    }

    function showError(resp) {
      resp.body.json().then(res => {
        const state = getState();
        const bundle = bundleSelector(state);
        const msgParams = { bundle, error: res, def: 'vehicle_add_status_failed' };
        dispatch(addErrorMessage(msgParams));
      });
    }

    const { statusDate, hour, minute, endStatusDate, endHour, endMinute, status, comment } = trimValues(form);

    const momentStartDate = moment(statusDate);

    momentStartDate.hour(hour);
    momentStartDate.minute(minute);

    let params = {
      date: momentStartDate.toDate(),
      type: status
    };

    dispatch({ type: types.VEHICLES_SAVE_STATUS_REQUEST });

    const endHourEmpty = isEmpty(endHour);
    const endMinuteEmpty = isEmpty(endMinute);

    const addEndStatus = endStatusDate || !endHourEmpty || !endMinuteEmpty;

    if (addEndStatus) {
      const autoEndDate = endStatusDate || statusDate;
      const autoEndHour = endHourEmpty ? hour : endHour;
      const autoEndMinute = endMinuteEmpty ? minute : endMinute;

      const momentEndDate = moment(autoEndDate);

      if (!isEmpty(autoEndHour)) momentEndDate.hour(autoEndHour);
      if (!isEmpty(autoEndMinute)) momentEndDate.minute(autoEndMinute);

      params.endDate = momentEndDate.toDate();
    }

    if (comment) params.comment = comment;

    return dispatch(callApi('vehicles', 'setNewStatus', { vehicleId, params })).then(showSuccess, showError);
  };
}

export function deleteStatus(statusId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DELETE_STATUS_REQUEST
    });

    return dispatch(callApi('vehicles', 'deleteStatus', statusId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_DELETE_STATUS_SUCCESS
      });
      return data;
    });
  };
}

export function updateCleanlinessStatus(detailVehicle) {
  const vehicleId = detailVehicle.id;
  const statuses = [CLEANLINESS_STATUS_CLEAN, CLEANLINESS_STATUS_DIRTY];
  const cleanliness = statuses[Number(_get(detailVehicle, 'cleanliness.status') === CLEANLINESS_STATUS_CLEAN)];

  return dispatch => {
    dispatch({ type: types.VEHICLES_UPDATE_CLEANLINESS_STATUS_REQUEST });

    return dispatch(callApi('vehicles', 'updateCleanlinessStatus', { vehicleId, cleanliness }))
      .then(() => {
        dispatch({
          type: types.VEHICLES_UPDATE_CLEANLINESS_STATUS_SUCCESS,
          status: cleanliness
        });

        dispatch(closeChangeStatusModal());
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_update_cleanliness_status_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_update_cleanliness_status_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function setFleetCurrentSortedIndex(index) {
  return {
    type: types.VEHICLES_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setFleetSortIsDescending(isDescending) {
  return {
    type: types.VEHICLES_SORT_DIRECTION_SET,
    isDescending
  };
}

export function unlockVehicle(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_UNLOCK_REQUEST
    });

    return dispatch(callApi('vehicles', 'unlockVehicle', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_UNLOCK_SUCCESS
      });
      return data;
    });
  };
}

export function lockVehicle(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_LOCK_REQUEST
    });

    return dispatch(callApi('vehicles', 'lockVehicle', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_LOCK_SUCCESS
      });
      return data;
    });
  };
}

export function getDeviceStatus(vehicleId, loader = false) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_GET_DEVICE_STATUS_REQUEST
    });

    return dispatch(callApi('vehicles', 'getDeviceStatus', vehicleId, loader)).then(function(data) {
      dispatch({
        type: types.VEHICLES_GET_DEVICE_STATUS_SUCCESS,
        status: data
      });
    });
  };
}

export function getVehicleDeviceID(vin) {
  return (dispatch, getState) => {
    dispatch({
      type: types.VEHICLES_GET_DEVICE_ID_REQUEST
    });

    return dispatch(callApi('vehicles', 'getVehicleDeviceId', vin))
      .then(function(data) {
        dispatch({
          type: types.VEHICLES_GET_DEVICE_ID_SUCCESS
        });

        const state = getState();
        const { detailVehicle } = state.vehicles;
        const vehicleId = detailVehicle.id;
        const serviceLevelType = detailVehicle.serviceLevelType;
        const versionId = detailVehicle.version.id;
        const fuelType = detailVehicle.fuelType;
        const registrationNumber = detailVehicle.registrationNumber;
        const seats = detailVehicle.seats;
        const categoryId = detailVehicle.category.id;
        const transmissionType = detailVehicle.transmissionType;
        const type = detailVehicle.type;
        const usage = detailVehicle.usage;
        const systemType = detailVehicle.systemType;
        const vehicleAccessories = detailVehicle.accessories;
        const colorId = detailVehicle.color.id;
        const doorsNumber = detailVehicle.doorsNumber;
        const description = detailVehicle.description;
        const withKey = detailVehicle.device.withKey;
        const pictureUrl = detailVehicle.pictureUrl;
        const installationDate = detailVehicle.device.installationDate;
        const registrationDocumentId = detailVehicle.registrationDocumentId;
        const registrationDate = detailVehicle.registrationDate;

        let accessories = [];
        vehicleAccessories.forEach((item, index) => {
          if (vehicleAccessories[index]) {
            accessories.push(item);
          }
        });

        let params = {
          vehicleId,
          vin,
          serviceLevelType,
          versionId,
          fuelType,
          registrationNumber,
          categoryId,
          transmissionType,
          description,
          type,
          systemType,
          usage,
          seats,
          colorId,
          doorsNumber,
          registrationDate,
          pictureUrl,
          registrationDocumentId,
          accessories,
          device: { serialNumber: data.vehicleId, withKey, installationDate }
        };

        dispatch(callApi('vehicles', 'updateMIBVinVehicle', params)).then(
          successData => {
            const deviceSerial = successData.device.serialNumber;
            dispatch({ type: types.VEHICLES_UDPATE_DEVICE_SERIAL_NUMBER_SUCCESS, deviceSerial });
            dispatch(
              addFlashMessage({
                contentKey: 'vehicles_set_device_serial_number_by_vin_success',
                type: FLASH_MESSAGE_TYPE_SUCCESS
              })
            );
          },
          error => dispatch(addErrorMessage({ error }))
        );
        // return data;
      })
      .catch(() =>
        dispatch(
          addFlashMessage({
            contentKey: 'vehicles_get_device_by_vin_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        )
      );
  };
}

export function exportVehiclesListWithSearch(params, paginated) {
  return (dispatch, getState) => {
    const state = getState();
    const pageNumber = _get(params, 'page.number') || apiParams.default.page.number;
    const pageSize = _get(params, 'page.size') || apiParams.default.page.size;
    const paramsObj = paginated
      ? {
          page: {
            number: pageNumber,
            size: pageSize
          }
        }
      : {};

    trySet(paramsObj, 'companyIds', append([selectCompanyId(state)]));
    trySet(paramsObj, 'subCompanyIds', append([selectSubCompanyId(state)]));

    _assign(paramsObj, pickArrayValues(params, ['vehicleStatus', 'systemType', 'category', 'cleanliness', 'type']));
    _assign(
      paramsObj,
      pickValidValues(params, [
        'transmissionType',
        'fuelType',
        'accessories',
        'brand',
        'model',
        'color',
        'version',
        'plateNumber',
        'companyIds',
        'subCompanyIds',
        'fuelTypes',
        'transmissionType',
        'includeUninstalledDevice'
      ])
    );

    const query = encodeURIComponent(JSON.stringify(paramsObj));

    if (!_isEmpty(paramsObj))
      return dispatch(callApi('vehicles', 'exportVehiclesCSV', { query, token: state.api.token }, false))
        .then(data => {
          dispatch({
            type: types.VEHICLES_EXPORT_REQUEST
          });
          dispatch(poolExport(data, 'vehicles'));
          return data;
        })
        .catch(() => {
          dispatch(
            addFlashMessage({
              contentKey: 'vehicles_download_failed',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        });
  };
}

export function setCurrentDetailVehicleTabIndex(index) {
  return {
    type: types.VEHICLES_DETAIL_SET_CURRENT_INDEX,
    index
  };
}

export function requestLeaseContractCreate() {
  return (dispatch, getState) => {
    const state = getState();

    const vehicleId = state.vehicles.detailVehicle.id;

    const { actualEndDate, amountExVat, contractNumber, endDate, serviceLevel, startDate, totalKm } = trimValues(
      state.form.leaseContractForm
    );

    const params = {
      actualEndDate,
      amountExVat,
      contractNumber,
      endDate,
      serviceLevel,
      startDate,
      totalKm
    };

    dispatch({
      type: types.VEHICLES_DETAIL_CREATE_LEASE_CONTRACT_REQUEST,
      params
    });

    return dispatch(callApi('vehicles', 'createLeaseContract', { vehicleId, params }));
  };
}

export function callCreateLeaseContractSuccess() {
  return {
    type: types.VEHICLES_DETAIL_CREATE_LEASE_CONTRACT_SUCCESS
  };
}

export function requestInsuranceContractCreate() {
  return (dispatch, getState) => {
    const state = getState();

    const vehicleId = state.vehicles.detailVehicle.id;

    const { contractNumber, startDate, expirationDate, glassBreakage, options, professionalType, privateType, contractFile } = trimValues(
      state.form.insuranceContractForm
    );

    const contractTypes = _filter([privateType ? 'PRIVATE' : null, professionalType ? 'PROFESSIONAL' : null], val => {
      return val !== null;
    });

    const params = {
      contractNumber,
      startDate,
      expirationDate,
      glassBreakage,
      types: contractTypes
    };

    if (options && options !== '') {
      params.options = options;
    }

    if (contractFile) {
      const props = {
        file: contractFile.rawFile,
        mimeType: contractFile.mimeType,
        isPublicFile: true
      };
      return dispatch(uploadFile(props)).then(file => {
        params.uploadedFileId = file;

        dispatch({
          type: types.VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_REQUEST,
          params
        });

        return dispatch(callApi('vehicles', 'createInsuranceContract', { vehicleId, params }));
      });
    } else {
      return dispatch(callApi('vehicles', 'createInsuranceContract', { vehicleId, params }));
    }
  };
}

export function callCreateInsuranceContractSuccess() {
  return {
    type: types.VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_SUCCESS
  };
}

export function getInsuranceContractImage(fileId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_REQUEST
    });

    return dispatch(callApi('file', 'getPublicFile', fileId))
      .then(data => {
        dispatch({
          type: types.VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_SUCCESS,
          insuranceContractImage: data
        });
        downloadImage(data.url);
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'vehicles_open_file_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function requestFuelCardCreate() {
  return (dispatch, getState) => {
    const state = getState();

    const vehicleId = state.vehicles.detailVehicle.id;

    const { cardNumber, expirationDate, startDate, endDate, pinCode, toll, wash } = trimValues(state.form.fuelCardForm);

    const params = {
      cardNumber,
      expirationDate,
      startDate,
      endDate,
      pinCode,
      toll,
      wash
    };

    dispatch({
      type: types.VEHICLES_DETAIL_CREATE_FUEL_CARD_REQUEST,
      params
    });

    return dispatch(callApi('vehicles', 'createFuelCard', { vehicleId, params }));
  };
}

export function callCreateFuelCardSuccess() {
  return {
    type: types.VEHICLES_DETAIL_CREATE_FUEL_CARD_SUCCESS
  };
}

export function requestAutolibCardCreate() {
  return (dispatch, getState) => {
    const state = getState();

    const vehicleId = state.vehicles.detailVehicle.id;

    const { cardNumber, expirationDate, startDate, endDate, pinCode, ownerName } = trimValues(state.form.autolibCardForm);

    const params = {
      cardNumber,
      expirationDate,
      startDate,
      endDate,
      pinCode,
      ownerName
    };

    dispatch({
      type: types.VEHICLES_DETAIL_CREATE_AUTOLIB_CARD_REQUEST,
      params
    });

    return dispatch(callApi('vehicles', 'createAutolibCard', { vehicleId, params }));
  };
}

export function callCreateAutolibCardSuccess() {
  return {
    type: types.VEHICLES_DETAIL_CREATE_AUTOLIB_CARD_SUCCESS
  };
}

export function getLeaseContractsList(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_GET_LEASE_CONTRACTS_REQUEST
    });

    return dispatch(callApi('vehicles', 'getLeaseContractsList', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_DETAIL_GET_LEASE_CONTRACTS_SUCCESS,
        list: data
      });
    });
  };
}

export function getInsuranceContractsList(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_REQUEST
    });

    return dispatch(callApi('vehicles', 'getInsuranceContractsList', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_SUCCESS,
        list: data
      });
    });
  };
}

export function getFuelCardsList(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_GET_FUEL_CARDS_REQUEST
    });

    return dispatch(callApi('vehicles', 'getFuelCardsList', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_DETAIL_GET_FUEL_CARDS_SUCCESS,
        list: data
      });
    });
  };
}

export function getAutolibCardsList(vehicleId) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_GET_AUTOLIB_CARDS_REQUEST
    });

    return dispatch(callApi('vehicles', 'getAutolibCardsList', vehicleId)).then(function(data) {
      dispatch({
        type: types.VEHICLES_DETAIL_GET_AUTOLIB_CARDS_SUCCESS,
        list: data
      });
    });
  };
}

export function deleteLeaseContract(id) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_DELETE_LEASE_CONTRACT_REQUEST
    });

    return dispatch(callApi('vehicles', 'deleteLeaseContract', id)).then(function() {
      dispatch({
        type: types.VEHICLES_DETAIL_DELETE_LEASE_CONTRACT_SUCCESS
      });
    });
  };
}

export function deleteInsuranceContract(id) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.VEHICLES_DETAIL_DELETE_INSURANCE_CONTRACT_REQUEST
    });

    return dispatch(callApi('vehicles', 'deleteInsuranceContract', id))
      .then(function() {
        dispatch({
          type: types.VEHICLES_DETAIL_DELETE_INSURANCE_CONTRACT_SUCCESS
        });

        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_delete_contract_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        dispatch(getInsuranceContractsList(state.vehicles.detailVehicle.id));
      })
      .catch(function(error) {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }

        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_delete_contract_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function deleteFuelCard(id) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_DELETE_FUEL_CARD_REQUEST
    });

    return dispatch(callApi('vehicles', 'deleteFuelCard', id)).then(function() {
      dispatch({
        type: types.VEHICLES_DETAIL_DELETE_FUEL_CARD_SUCCESS
      });
    });
  };
}

export function deleteAutolibCard(id) {
  return dispatch => {
    dispatch({
      type: types.VEHICLES_DETAIL_DELETE_AUTOLIB_CARD_REQUEST
    });

    return dispatch(callApi('vehicles', 'deleteAutolibCard', id)).then(function() {
      dispatch({
        type: types.VEHICLES_DETAIL_DELETE_AUTOLIB_CARD_SUCCESS
      });
    });
  };
}

export function getRegistrationFileInfo(fileId, loader = true) {
  return dispatch => {
    return dispatch(callApi('file', 'getPublicFile', fileId, loader)).then(info => {
      dispatch({
        type: types.VEHICLES_EDIT_REGISTRATION_FILE_INFOS_SUCCESS,
        fileInfo: info
      });
    });
  };
}

export function clearRegisterFileData() {
  return {
    type: types.VEHICLES_CLEAR_REGISTRATION_FILE_INFOS_SUCCESS
  };
}

export function displayVehicleImage(fileData, type) {
  return {
    type,
    fileData: {
      content: fileData.content.split(';base64,')[1],
      mimeType: fileData.mimeType,
      name: fileData.name
    }
  };
}

export function removeImage(type) {
  return {
    type
  };
}

export function getVehicleReports(params, loader = false) {
  return dispatch => {
    dispatch({ type: types.VEHICLES_GET_REPORTS_REQUEST });

    const dateNames = ['from', 'to'];
    const newParams = _omit(params, dateNames);
    const dateParams = _pick(params, dateNames);

    _forEach(dateParams, (v, k) => {
      trySet(newParams, k, localToUTC(v));
    });

    return dispatch(callApi('vehicles', 'getReports', newParams, loader)).then(
      data => {
        const list = _get(data, 'results', []);
        const pageSize = _get(data, 'size', 15);
        const totalPages = _get(data, 'totalPages', 0);
        const totalResults = _get(data, 'totalResults', 0);
        const currentPage = _get(data, 'number', 1);
        const currentSize = _get(list, 'length', 0);

        const metadata = {
          paginationInfo: {
            currentPage,
            currentSize,
            pageSize,
            totalPages,
            totalResults
          }
        };

        return dispatch({
          type: types.VEHICLES_GET_REPORTS_SUCCESS,
          list,
          metadata,
          params: newParams
        });
      },
      error => {
        return dispatch({
          type: types.VEHICLES_GET_REPORTS_ERROR,
          error
        });
      }
    );
  };
}

export function clearReportsData() {
  return {
    type: types.VEHICLES_CLEAR_REPORTS
  };
}

export function getVehicleTasks(vehicleId) {
  return dispatch => {
    dispatch({ type: types.VEHICLE_GET_TASKS_REQUEST });

    return dispatch(callApi('vehicleTasks', 'getList', vehicleId)).then(
      data => {
        const list = data;
        const pageSize = _get(data, 'size', 15);
        const totalPages = _get(data, 'totalPages', 0);
        const totalResults = _get(data, 'totalResults', 0);
        const currentPage = _get(data, 'number', 1);
        const currentSize = _get(list, 'length', 0);

        const metadata = {
          paginationInfo: {
            currentPage,
            currentSize,
            pageSize,
            totalPages,
            totalResults
          }
        };

        return dispatch({
          type: types.VEHICLE_GET_TASKS_SUCCESS,
          list,
          metadata
        });
      },
      error => {
        return dispatch({
          type: types.VEHICLE_GET_TASKS_ERROR,
          error
        });
      }
    );
  };
}

export function vehicleClearTasks() {
  return {
    type: types.VEHICLE_CLEAR_TASKS_DATA
  };
}

export function getFieldAgents() {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.vehicles.detailVehicle.company.id;
    const payload = { companyIds: [companyId], role: BACKUSER_ROLE_FIELD_AGENT, page: { number: 1, size: 25 } };

    dispatch({ type: types.BACKUSERS_GET_FIELD_AGENTS_REQUEST });

    return dispatch(callApi('backUsers', 'search', payload)).then(
      data => {
        const list = _get(data, 'results', []);

        return dispatch({
          type: types.BACKUSERS_GET_FIELD_AGENTS_SUCCESS,
          list
        });
      },
      error => {
        return dispatch({
          type: types.BACKUSERS_GET_FIELD_AGENTS_ERROR,
          error
        });
      }
    );
  };
}

export function addVehicleTask() {
  return (dispatch, getState) => {
    const state = getState();

    const form = state.form.AddTaskForm;
    const vehicleId = state.vehicles.detailVehicle.id;

    dispatch({ type: types.VEHICLE_ADD_TASK_REQUEST });

    const { startDate, startHour, startMinute, fieldAgentId, type } = trimValues(form);

    const momentStartDate = moment(startDate);

    momentStartDate.hour(startHour);
    momentStartDate.minute(startMinute);

    let params = {
      startDate: momentStartDate.toDate(),
      type,
      fieldAgentId,
      vehicleId
    };

    return dispatch(callApi('vehicleTasks', 'addTask', params)).then(
      () => {
        return dispatch({
          type: types.VEHICLE_ADD_TASK_SUCCESS
        });
      },
      error => {
        return dispatch({
          type: types.VEHICLE_ADD_TASK_ERROR,
          error
        });
      }
    );
  };
}
