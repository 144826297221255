import { UPDATE_LOCATION } from 'react-router-redux';
import _get from 'lodash/get';

import config from '../constants/config-constants';
import { vehicleAccessories } from '../constants/options-constants';

import * as types from '../constants/actionTypes-constants';
import moment from 'moment';

const damagesDefault = {
  damagesParams: {},
  damagesList: [],
  damagesError: false,
  damagesLoading: false,
  damagesMetadata: {}
};

const tasksDefault = {
  tasksParams: {},
  tasksList: [],
  tasksError: false,
  tasksLoading: false,
  tasksMetadata: {},
  agentsList: []
};

export default function vehicles(
  state = {
    paginatedResults: [],
    formFieldInfos: null,
    vehicleFormActiveTabIndex: 0,
    latestCreatedVehicle: null,
    imagePreviewVehicle: false,
    categories: [],
    detailVehicle: {},
    vehicleCopy: {},
    vehiclesFiltersFormIsOpen: false,
    vehiclesListIsFiltered: false,
    openedChangeStatusModal: false,
    openedDeleteStatusModal: false,
    confirmAddStatusModal: false,
    statuses: null,
    statusToDelete: null,
    urlParams: null,
    currentSortedIndex: null,
    sortIsDescending: false,
    deviceStatus: null,
    exporting: null,
    insuranceContractCurrentImage: null,
    currentDetailTabIndex: 0,
    leaseContractsList: [],
    insuranceContractsList: [],
    fuelCardsList: [],
    autolibCardsList: [],
    loadingList: true,
    loadingError: false,
    deviceRemovalReminder: '',
    registrationFileImage: null,
    ...damagesDefault,
    ...tasksDefault
  },
  action
) {
  switch (action.type) {
    case types.VEHICLES_GET_DETAIL_VEHICLE_REQUEST:
    case types.VEHICLES_LOCK_SUCCESS:
    case types.VEHICLES_UNLOCK_SUCCESS:
      return state;

    case types.VEHICLES_CLEAR_LIST_DATA:
      return {
        ...state,
        paginatedResults: []
      };

    case types.VEHICLES_GET_FLEET_REQUEST:
      return {
        ...state,
        loadingError: false,
        loadingList: true
      };

    case types.VEHICLES_UPDATE_CLEANLINESS_STATUS_SUCCESS:
      return {
        ...state,
        detailVehicle: {
          ...state.detailVehicle,
          cleanliness: {
            ..._get(state.detailVehicle, 'cleanliness', {}),
            status: action.status
          }
        }
      };

    case types.VEHICLES_GET_FLEET_ERROR:
      return {
        ...state,
        loadingError: true
      };

    case types.VEHICLES_GET_FLEET_SUCCESS:
      return {
        ...state,
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false,
        vehicleCopy: {}
      };

    case types.VEHICLES_GET_FORM_FIELD_INFOS_SUCCESS:
      return Object.assign({}, state, {
        formFieldInfos: action.data
      });

    case types.VEHICLE_FORM_CREATE_NEW_VEHICLE_SUCCESS:
      return Object.assign({}, state, {
        latestCreatedVehicle: action.vehicle,
        copyVehicle: {}
      });

    case types.VEHICLE_SET_DEVICE_REMOVAL_REMINDER: {
      const { device } = action.vehicle || {};
      const { removalDate } = device || {};
      return { ...state, deviceRemovalReminder: removalDate || '' };
    }

    case types.VEHICLE_FORM_CREATE_NEW_VEHICLE_INIT:
      return Object.assign({}, state, {
        vehicleFormActiveTabIndex: 0
      });

    case types.VEHICLE_COPY_TO_CREATE_NEW: {
      const currentVehicle = state.detailVehicle;
      let carOptions = {};
      vehicleAccessories.forEach((item, index) => {
        carOptions[index] = currentVehicle.accessories.indexOf(item.value) !== -1;
      });
      const siteLocation =
        currentVehicle.lastPosition && currentVehicle.lastPosition.parking ? currentVehicle.lastPosition.parking.site.id : '';
      const parkingLocation =
        currentVehicle.lastPosition && currentVehicle.lastPosition.parking ? currentVehicle.lastPosition.parking.id : '';
      const vehicleCopy = {
        transmissionType: currentVehicle.transmissionType,
        fuelType: currentVehicle.fuelType,
        carBrand: currentVehicle.version.model.brand.id,
        carModel: currentVehicle.version.model.id,
        carVersion: currentVehicle.version.id,
        numberOfSeats: currentVehicle.seats,
        vehicleClass: currentVehicle.category.id,
        vehicleColor: currentVehicle.color ? currentVehicle.color.id : '',
        pictureData: { image: currentVehicle.pictureUrl },
        vehicleOwner: currentVehicle.ownedByRci
          ? config.vehicleOwnerChoicesValues.default
          : config.vehicleOwnerChoicesValues.currentCompany,
        usage: currentVehicle.usage,
        siteLocation,
        parkingLocation,
        serviceLevelType: 'BASIC',
        vehicleOptions: carOptions,
        type: currentVehicle.type,
        doorsNumber: currentVehicle.doorsNumber,
        deviceInstallation: moment().toDate()
      };
      return Object.assign({}, state, {
        vehicleCopy
      });
    }

    case types.VEHICLE_FORM_IMAGE_PREVIEW_HIDE:
      return Object.assign({}, state, {
        imagePreviewVehicle: false
      });

    case types.VEHICLES_GET_CATEGORIES_LIST_SUCCESS:
      return Object.assign({}, state, {
        categories: action.data
      });

    case types.VEHICLES_GET_DETAIL_VEHICLE_SUCCESS:
      return Object.assign({}, state, {
        detailVehicle: action.vehicle
      });

    case types.VEHICLES_FILTERS_FORM_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        vehiclesFiltersFormIsOpen: !state.vehiclesFiltersFormIsOpen
      });

    case types.VEHICLES_OPEN_CHANGE_STATUS_MODAL:
      return Object.assign({}, state, {
        openedChangeStatusModal: true
      });

    case types.VEHICLES_CLOSE_CHANGE_STATUS_MODAL:
      return Object.assign({}, state, {
        openedChangeStatusModal: false
      });

    case types.VEHICLES_CONFIRM_ADD_STATUS_MODAL_OPEN:
      return Object.assign({}, state, {
        confirmAddStatusModal: true
      });

    case types.VEHICLES_CONFIRM_ADD_STATUS_MODAL_CLOSE:
      return Object.assign({}, state, {
        confirmAddStatusModal: false
      });

    case types.VEHICLES_OPEN_DELETE_STATUS_MODAL:
      return Object.assign({}, state, {
        openedDeleteStatusModal: true,
        statusToDelete: action.statusId
      });

    case types.VEHICLES_CLOSE_DELETE_STATUS_MODAL:
      return Object.assign({}, state, {
        openedDeleteStatusModal: false
      });

    case types.VEHICLES_GET_STATUSES_SUCCESS:
      return Object.assign({}, state, {
        statuses: action.statuses
      });

    case types.VEHICLES_SET_CURRENT_SORTED_INDEX:
      return Object.assign({}, state, {
        currentSortedIndex: action.index
      });

    case types.VEHICLES_SORT_DIRECTION_SET:
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });

    case types.VEHICLES_GET_DEVICE_STATUS_REQUEST:
      return Object.assign({}, state, {
        deviceStatus: null
      });

    case types.VEHICLES_GET_DEVICE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        deviceStatus: action.status
      });

    case types.VEHICLES_LOCK_REQUEST:
    case types.VEHICLES_UNLOCK_REQUEST:
      return Object.assign({}, state, {
        deviceStatus: null
      });

    case types.VEHICLES_EXPORT_REQUEST:
      return Object.assign({}, state, {
        exporting: true
      });

    case types.VEHICLES_EXPORT_FINISHED:
      return Object.assign({}, state, {
        exporting: null
      });

    case types.VEHICLES_DETAIL_SET_CURRENT_INDEX:
      return Object.assign({}, state, {
        currentDetailTabIndex: action.index
      });

    case types.VEHICLES_DETAIL_GET_LEASE_CONTRACTS_SUCCESS:
      return Object.assign({}, state, {
        leaseContractsList: action.list
      });

    case types.VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_SUCCESS:
      return Object.assign({}, state, {
        insuranceContractsList: action.list
      });

    case types.VEHICLES_DETAIL_GET_FUEL_CARDS_SUCCESS:
      return Object.assign({}, state, {
        fuelCardsList: action.list
      });

    case types.VEHICLES_DETAIL_GET_AUTOLIB_CARDS_SUCCESS:
      return Object.assign({}, state, {
        autolibCardsList: action.list
      });

    case types.VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        detailVehicle: Object.assign({}, state.detailVehicle, {
          insuranceContractImage: action.insuranceContractImage
        })
      });

    case types.VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_REQUEST:
      if (!state.detailVehicle.insuranceContractImage) {
        return state;
      }
      return Object.assign({}, state, {
        detailVehicle: Object.assign({}, state.detailVehicle, {
          insuranceContractImage: null
        })
      });

    case types.VEHICLES_EDIT_REGISTRATION_FILE_INFOS_SUCCESS: {
      return {
        ...state,
        registrationFileImage: action.fileInfo
      };
    }

    case types.VEHICLES_CLEAR_REGISTRATION_FILE_INFOS_SUCCESS: {
      return {
        ...state,
        registrationFileImage: null
      };
    }

    case types.VEHICLES_CLEAR_CURRENT:
      return {
        ...state,
        detailVehicle: {}
      };

    case types.VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_SUCCESS:
      return Object.assign({}, state, {
        insuranceContractCurrentImage: null
      });

    case types.VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        insuranceContractCurrentImage: action.fileData
      });

    case types.VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_REMOVE:
      return Object.assign({}, state, {
        insuranceContractCurrentImage: null
      });

    case UPDATE_LOCATION:
      if (action.path === '/') {
        return Object.assign({}, state, {
          vehicleFormActiveTabIndex: 0,
          imagePreviewVehicle: false,
          openedChangeStatusModal: false,
          openedDeleteStatusModal: false,
          insuranceContractCurrentImage: null,
          statusToDelete: null,
          currentSortedIndex: null,
          sortIsDescending: false,
          deviceStatus: null
        });
      } else {
        return Object.assign({}, state, {
          deviceStatus: null
        });
      }

    case types.VEHICLES_CLEAR_REPORTS:
      return {
        ...state,
        ...damagesDefault
      };

    case types.VEHICLES_GET_REPORTS_REQUEST:
      return {
        ...state,
        damagesError: false,
        damagesLoading: true
      };

    case types.VEHICLES_GET_REPORTS_SUCCESS:
      return {
        ...state,
        damagesParams: action.params,
        damagesList: action.list,
        damagesLoading: false,
        damagesMetadata: action.metadata
      };

    case types.VEHICLES_UDPATE_DEVICE_SERIAL_NUMBER_SUCCESS:
      return {
        ...state,
        detailVehicle: {
          ...state.detailVehicle,
          device: {
            ...state.detailVehicle.device,
            serialNumber: action.deviceSerial
          }
        }
      };
    case types.VEHICLES_GET_REPORTS_ERROR:
      return {
        ...state,
        damagesError: true,
        damagesLoading: false,
        damagesList: []
      };
    case types.VEHICLE_GET_TASKS_REQUEST:
      return {
        ...state,
        tasksError: false,
        tasksLoading: true
      };

    case types.VEHICLE_GET_TASKS_SUCCESS:
      return {
        ...state,
        tasksParams: action.params,
        tasksList: action.list,
        tasksLoading: false,
        tasksMetadata: action.metadata
      };

    case types.VEHICLE_GET_TASKS_ERROR:
      return {
        ...state,
        tasksError: true,
        tasksLoading: false,
        tasksList: []
      };
    case types.VEHICLE_CLEAR_TASKS_DATA:
      return {
        ...state,
        tasksError: false,
        tasksLoading: false,
        tasksList: [],
        agentsList: []
      };
    case types.BACKUSERS_GET_FIELD_AGENTS_REQUEST:
      return {
        ...state
      };

    case types.BACKUSERS_GET_FIELD_AGENTS_SUCCESS:
      return {
        ...state,
        agentsList: action.list
      };

    case types.BACKUSERS_GET_FIELD_AGENTS_ERROR:
      return {
        ...state,
        agemtsError: true,
        agentsList: []
      };

    default:
      return state;
  }
}
