import React, { Component, PropTypes } from 'react';
import TextField from 'material-ui/TextField';
import { FormattedMessage } from 'react-intl';
import CameraIcon from 'material-ui/svg-icons/image/camera-alt';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import _ from 'lodash';
import { reduxForm, addArrayValue, change } from 'redux-form';
import { connect } from 'react-redux';
import DatePicker from 'material-ui/DatePicker';
import moment from 'moment';
import objectFitImages from 'object-fit-images';
import Close from 'material-ui/svg-icons/navigation/close';
import classnames from 'classnames';
import {
  getBrandsList,
  getModelsList,
  getCategoriesRequest,
  getColors,
  getVersionsList,
  getParkingsList,
  emptyVersionList,
  getRegistrationFileInfo,
  clearRegisterFileData,
  clearParkingsList
} from '../../actions/all-actions';
import { createValidator, notEmpty, url, fileValidation, vinNumber, number } from '../../validation/all-validation';
import FileImage from '../FileImage/FileImage';
import { VEHICLE_SYSTEM_INVERS, VEHICLE_SYSTEM_MOVINBLUE } from '../../constants/backend-constants';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import BackLink from '../BackLink/BackLink';
import routes from '../../constants/routes-constants';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import ButtonUpload from '../ButtonUpload/ButtonUpload';
import {
  vehicleAccessories,
  vehicleTypes,
  vehicleTransmissionTypes,
  vehicleFuelTypes,
  vehicleNumberOfDoors,
  vehicleOwners,
  allSystemTypes,
  vehicleKeyOptions
} from '../../constants/options-constants';
import { getAppFormattedDate, getMaterialLocale, scrollToFirstError, safe, addErrorMessage, isValidId } from '../../utils/utils';
import { apiParams } from '../../constants/api-params-constants';
import { ALL } from '../../constants/generic-constants';
import {
  currentContractVehicleValuesSelector,
  headerContractBookingValuesSelector,
  localeSelector,
  vehicleUsageTypesSelector
} from '../../selectors/all-selectors';
import EkTextarea from '../EkTextarea/EkTextarea';
import { Dialog, FlatButton } from 'material-ui';
import Compressor from 'compressorjs';
import config from '../../constants/config-constants';
import SlimerSelect from '../../components/SlimerSelect';
import { createSitesOptions } from '../../containers/ScheduledExport/utils';
import ImagePreview from '../ImagePreview/ImagePreview';

const datePickerTextStyle = {
  maxWidth: '100%',
  width: '235px'
};

class VehicleForm extends Component {
  setCallbacks() {
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleChangeTextField = this.handleChangeTextField.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.setFileUploadRef = this.setFileUploadRef.bind(this);
    this.clickOnFileUpload = this.clickOnFileUpload.bind(this);
    this.handleOpenCarImageDialog = this.handleOpenCarImageDialog.bind(this);
    this.handleCloseCarImageDialog = this.handleCloseCarImageDialog.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.handleChangePictureUrlValue = this.handleChangePictureUrlValue.bind(this);
    this.handleChangePictureUrl = this.handleChangePictureUrl.bind(this);
    this.helperAccesories = this.helperAccesories.bind(this);

    this.onSiteLocationSelect = data => {
      const { props: p } = this;
      if (data && data.length) {
        if (isValidId(data[0])) {
          p.dispatch(getParkingsList(data[0], false)).then(parkingsList => {
            if (parkingsList.length > 0) {
              p.dispatch(change('vehicleForm', 'parkingLocation', [parkingsList[0].id]));
            }
          });
        } else {
          p.dispatch(clearParkingsList());
        }
      }
    };
  }

  setVars() {
    this.state = {
      openCarImageDialog: false,
      pictureData: '',
      pictureUrl: ''
    };
  }

  componentWillMount() {
    const { dispatch, datas, editMode, companyId } = this.props;
    const { carBrand, carModel } = datas || {};
    this.setCallbacks();
    this.setVars();
    if (!editMode && !_.isEmpty(datas) && isValidId(companyId)) {
      if (carBrand) dispatch(getModelsList(carBrand, false));
      if (carModel) dispatch(getVersionsList(carModel, false));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.datas.vehicleOptions !== this.props.datas.vehicleOptions) {
      this.helperAccesories();
    }
    if (!this.props.editMode) {
      if (nextProps.sitesList !== this.props.sitesList && nextProps.sitesList.length > 0) {
        this.props.dispatch(change('vehicleForm', 'siteLocation', [nextProps.sitesList[0].id]));
      }
      if (nextProps.parkingList !== this.props.parkingList && nextProps.parkingList.length > 0) {
        this.props.dispatch(change('vehicleForm', 'parkingLocation', [nextProps.parkingList[0].id]));
      }
    }
  }

  componentDidMount() {
    const { dispatch, datas } = this.props;
    const registrationDocumentId = _.get(datas, 'registrationFile.id', false);
    this.setCallbacks();
    dispatch(getBrandsList(false));
    dispatch(getColors(false));
    dispatch(getCategoriesRequest(false));
    objectFitImages();

    registrationDocumentId && dispatch(getRegistrationFileInfo(registrationDocumentId, false));

    this.helperAccesories();
  }

  componentWillUnmount() {
    this.props.dispatch(clearRegisterFileData());
    this.props.dispatch(clearParkingsList());
  }

  handleOpenCarImageDialog() {
    this.setState({ openCarImageDialog: true });
  }

  handleCloseCarImageDialog() {
    this.setState({ openCarImageDialog: false });
  }

  handleUploadClick() {
    this.setState({ pictureUrl: '' });
    this.clickOnFileUpload();
    this.handleCloseCarImageDialog();
  }

  helperAccesories() {
    const { fields, datas } = this.props;
    vehicleAccessories.forEach((item, index) => {
      if (datas && datas.vehicleOptions) {
        fields.vehicleOptions.addField(datas.vehicleOptions[index]);
      } else {
        fields.vehicleOptions.addField();
      }
    });
  }

  handleChangeField(field, a, x, value) {
    const { dispatch, fields } = this.props;

    field.onChange(value);

    if (field.name === 'carBrand') {
      dispatch(getModelsList(value, false));
      dispatch(emptyVersionList());
      fields.carVersion.onChange('');
    }
    if (field.name === 'carModel') {
      dispatch(getVersionsList(value, false));
    }
    if (field.name === 'usedSystems') {
      if (value === VEHICLE_SYSTEM_MOVINBLUE || value === VEHICLE_SYSTEM_INVERS) dispatch(change('vehicleForm', 'deviceWithKey', 'true'));
      else dispatch(change('vehicleForm', 'deviceWithKey', 'false'));
    }
  }

  handleChangeTextField(field, e, value) {
    field.onChange(value);
  }

  handleChangePictureUrlValue(e, value) {
    this.setState({ pictureUrl: value });
  }

  handleChangePictureUrl() {
    const { pictureData } = this.props.fields;
    const url = this.state.pictureUrl.trim();

    if (url) {
      pictureData.onChange({ image: url });
    } else {
      pictureData.onChange(pictureData.initialValue);
    }

    this.handleCloseCarImageDialog();
  }

  formatDate(date) {
    return getAppFormattedDate(date);
  }

  onFileUpload(e) {
    e.preventDefault();
    const inputFile = e.target.files[0];
    if (!inputFile) return;

    return new Compressor(inputFile, {
      ...config.fileUploadCompression.vehicleImages,
      success: reducedFile => {
        const reader = new FileReader();

        reader.onload = upload => {
          this.props.fields.pictureData.onChange({
            image: upload.target.result,
            blob: reducedFile
          });
        };

        reader.readAsDataURL(reducedFile);
      },
      error: error => {
        const props = { error: { developerMessage: error.message } };
        this.props.dispatch(addErrorMessage(props));
      }
    });
  }

  setFileUploadRef(ref) {
    this.fileUploadRef = ref;
  }

  clickOnFileUpload() {
    safe(() => this.fileUploadRef.click());
  }

  addScrollToError(field) {
    if (field.error) {
      return <span className="fieldErrorMsg" style={{ display: 'none' }} />;
    }
  }

  render() {
    const cancelUrl = '/#' + routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)));
    const {
      brandList,
      modelList,
      versionList,
      colorList,
      categoriesList,
      sitesList,
      parkingList,
      handleSubmit,
      companyId,
      editMode,
      registrationFileImage,
      vehicleUsageTypes,
      vehicleRules,
      bookingRules,
      fields: {
        transmissionType,
        fuelType,
        carBrand,
        carModel,
        carVersion,
        numberOfSeats,
        vehicleClass,
        vehicleColor,
        pictureData,
        vinNumber,
        licencePlate,
        registrationDate,
        registrationFile,
        vehicleOwner,
        usage,
        siteLocation,
        parkingLocation,
        usedSystems,
        deviceSerialNumber,
        deviceInstallation,
        deviceRemoval,
        type,
        doorsNumber,
        description,
        deviceWithKey,
        proplannerId
      }
    } = this.props;

    if (companyId === ALL && editMode !== true) {
      return (
        <div className="mainContainer_content">
          <div className="pageContainer">
            <div className="page-info-block">
              <div className="newVehicleForm_noCompany">
                <FormattedMessage id="vehiclePlanning_selectCompany" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    const hasSites = sitesList && sitesList.length > 0;
    return (
      <form action="#" className="newVehicleForm">
        <BackLink link={cancelUrl} labelKey="back_link_vehicles_list" />
        <div className="newVehicleForm_container">
          <div className="newVehicleForm_subHeader">
            <FormattedMessage id="vehicleForm_description_title" />
          </div>
          <div className="newVehicleForm_row">
            <div className="newVehicleForm_rightColumn">
              <FieldErrorMsg field={pictureData} customClass="fieldErrorMsg--vehicleForm" />
              <div className="newVehicleForm_carImg">
                <div className="newVehicleForm_carImgHover" onClick={this.handleOpenCarImageDialog}>
                  <CameraIcon style={{ fill: 'white', height: '30px', width: '30px' }} />
                  <input
                    ref={this.setFileUploadRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={this.onFileUpload}
                  />
                </div>
                {pictureData.value ? (
                  <img src={pictureData.value.image} alt="" />
                ) : (
                  <img src="http://www.agomer.ee/veoautod/wp-content/themes/creativo/images/placeholder.png" alt="" />
                )}
              </div>
              <div className="newVehicleForm_checkBoxWrap">
                {vehicleAccessories.map((item, index) => {
                  return (
                    <LabeledCheckbox
                      customClass="vehicleForm_labeledCheckbox"
                      customLabelClass="vehicleForm_labeledCheckboxLabel"
                      key={item.value}
                      id={`option_${item.value}`}
                      field={this.props.fields.vehicleOptions[index]}
                    >
                      <FormattedMessage id={item.labelKey} />
                    </LabeledCheckbox>
                  );
                })}
              </div>
            </div>
            <div className="newVehicleForm_fieldWrap">
              <div className="newVehicleForm_field">
                <SelectField
                  value={carBrand.value}
                  onChange={_.partial(this.handleChangeField, carBrand)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_carBrand" />}
                  fullWidth
                  errorText={!carBrand.touched || !carBrand.error ? '' : <FormattedMessage id={carBrand.error.type.toLowerCase()} />}
                >
                  {brandList.map(brand => (
                    <MenuItem key={brand.id} value={brand.id} primaryText={brand.name} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={carModel.value}
                  onChange={_.partial(this.handleChangeField, carModel)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_carModel" />}
                  fullWidth
                  errorText={!carModel.touched || !carModel.error ? '' : <FormattedMessage id={carModel.error.type.toLowerCase()} />}
                >
                  {modelList.map(model => (
                    <MenuItem key={model.id} value={model.id} primaryText={model.name} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={carVersion.value}
                  onChange={_.partial(this.handleChangeField, carVersion)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_carVersion" />}
                  fullWidth
                  errorText={!carVersion.touched || !carVersion.error ? '' : <FormattedMessage id={carVersion.error.type.toLowerCase()} />}
                >
                  {versionList.map(version => (
                    <MenuItem key={version.id} value={version.id} primaryText={version.name} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={vehicleColor.value}
                  onChange={_.partial(this.handleChangeField, vehicleColor)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_vehicleColor" />}
                  fullWidth
                  errorText={
                    !vehicleColor.touched || !vehicleColor.error ? '' : <FormattedMessage id={vehicleColor.error.type.toLowerCase()} />
                  }
                >
                  {colorList.map(color => (
                    <MenuItem key={color.id} value={color.id} primaryText={color.code} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={type.value}
                  onChange={_.partial(this.handleChangeField, type)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_type" />}
                  fullWidth
                  errorText={!type.touched || !type.error ? '' : <FormattedMessage id={type.error.type.toLowerCase()} />}
                >
                  {vehicleTypes.map(type => (
                    <MenuItem key={type.value} value={type.value} primaryText={<FormattedMessage id={type.labelKey} />} />
                  ))}
                </SelectField>
              </div>
              {vehicleRules.vehicleCategory && (
                <div className="newVehicleForm_field">
                  <SelectField
                    value={vehicleClass.value}
                    onChange={_.partial(this.handleChangeField, vehicleClass)}
                    floatingLabelText={<FormattedMessage id="vehicleForm_vehicleClass" />}
                    fullWidth
                    errorText={
                      !vehicleClass.touched || !vehicleClass.error ? '' : <FormattedMessage id={vehicleClass.error.type.toLowerCase()} />
                    }
                  >
                    {categoriesList.map(category => (
                      <MenuItem key={category.id} value={category.id} primaryText={category.type} />
                    ))}
                  </SelectField>
                </div>
              )}
              <div className="newVehicleForm_field">
                <SelectField
                  value={transmissionType.value}
                  onChange={_.partial(this.handleChangeField, transmissionType)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_transmissionType" />}
                  fullWidth
                  errorText={
                    !transmissionType.touched || !transmissionType.error ? (
                      ''
                    ) : (
                      <FormattedMessage id={transmissionType.error.type.toLowerCase()} />
                    )
                  }
                >
                  {vehicleTransmissionTypes.map(type => (
                    <MenuItem key={type.value} value={type.value} primaryText={<FormattedMessage id={type.labelKey} />} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={fuelType.value}
                  onChange={_.partial(this.handleChangeField, fuelType)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_fuelType" />}
                  fullWidth
                  errorText={!fuelType.touched || !fuelType.error ? '' : <FormattedMessage id={fuelType.error.type.toLowerCase()} />}
                >
                  {vehicleFuelTypes.map(type => (
                    <MenuItem key={type.value} value={type.value} primaryText={<FormattedMessage id={type.labelKey} />} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={doorsNumber.value}
                  onChange={_.partial(this.handleChangeField, doorsNumber)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_doorsNumber" />}
                  fullWidth
                  errorText={
                    !doorsNumber.touched || !doorsNumber.error ? '' : <FormattedMessage id={doorsNumber.error.type.toLowerCase()} />
                  }
                >
                  {vehicleNumberOfDoors.map(number => (
                    <MenuItem key={number} value={number} primaryText={number} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={numberOfSeats.value}
                  onChange={_.partial(this.handleChangeField, numberOfSeats)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_numberOfSeats" />}
                  fullWidth
                  errorText={
                    !numberOfSeats.touched || !numberOfSeats.error ? '' : <FormattedMessage id={numberOfSeats.error.type.toLowerCase()} />
                  }
                >
                  {vehicleNumberOfDoors.map(number => (
                    <MenuItem key={number} value={number} primaryText={number} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field">
                <SelectField
                  value={vehicleOwner.value}
                  onChange={_.partial(this.handleChangeField, vehicleOwner)}
                  floatingLabelText={<FormattedMessage id="vehicleForm_vehicleOwner" />}
                  fullWidth
                  errorText={
                    !vehicleOwner.touched || !vehicleOwner.error ? '' : <FormattedMessage id={vehicleOwner.error.type.toLowerCase()} />
                  }
                >
                  {vehicleOwners.map(owner => (
                    <MenuItem key={owner.value} value={owner.value} primaryText={<FormattedMessage id={owner.labelKey} />} />
                  ))}
                </SelectField>
              </div>
              <div className="newVehicleForm_field_text">
                <EkTextarea labelKey="vehicleForm_vehicleDesc_label" customClass="createBooking_textarea" field={description} />
              </div>
            </div>
          </div>
        </div>
        <div className="newVehicleForm_container">
          <div className="newVehicleForm_subHeader">
            <FormattedMessage id="vehicle_detail_title_identification" />
          </div>
          <div className="newVehicleForm_fieldWrap">
            <div className="newVehicleForm_field">
              <TextField
                floatingLabelText={<FormattedMessage id="vehicleForm_vinNumber" />}
                fullWidth
                onChange={_.partial(this.handleChangeTextField, vinNumber)}
                value={vinNumber.value}
                errorText={!vinNumber.touched || !vinNumber.error ? '' : <FormattedMessage id={vinNumber.error.type.toLowerCase()} />}
              />
              {this.addScrollToError(vinNumber)}
            </div>
            <div className="newVehicleForm_field">
              <TextField
                floatingLabelText={<FormattedMessage id="vehicleForm_licencePlate" />}
                fullWidth
                onChange={_.partial(this.handleChangeTextField, licencePlate)}
                value={licencePlate.value}
                errorText={
                  !licencePlate.touched || !licencePlate.error ? '' : <FormattedMessage id={licencePlate.error.type.toLowerCase()} />
                }
              />
              {this.addScrollToError(licencePlate)}
            </div>
            <div className="newVehicleForm_field">
              <DatePicker
                floatingLabelText={<FormattedMessage id="vehicleForm_registrationDate" />}
                onChange={_.partial(this.handleChangeTextField, registrationDate)}
                value={registrationDate.value}
                autoOk
                locale={getMaterialLocale(this.props.locale)}
                DateTimeFormat={global.Intl.DateTimeFormat}
                formatDate={this.formatDate}
              />
            </div>
            {bookingRules.proplanner && (
              <div className="newVehicleForm_field">
                <TextField
                  floatingLabelText={<FormattedMessage id="vehicleForm_proplannerId" />}
                  fullWidth
                  onChange={_.partial(this.handleChangeTextField, proplannerId)}
                  value={proplannerId.value}
                />
              </div>
            )}
          </div>
          <div className="newVehicleForm_fieldWrap" style={{ marginLeft: '12px', flexDirection: 'column' }}>
            <ButtonUpload
              formRowItemKey="vehicleForm_driverLicenceUploadBtn"
              id="registrationFile"
              customClass="vehicleForm_buttonUpload"
              buttonLabel="vehicleForm_registrationFile_label"
              field={registrationFile}
            >
              <FieldErrorMsg field={registrationFile} customClass="fieldErrorMsg--vehicleForm" />
            </ButtonUpload>

            {_.get(registrationFile.value, 'content', false) && (
              <div style={{ marginTop: '5px' }}>
                <span>{registrationFile.value.name}</span>
                <FileImage imageObject={registrationFile.value} imageStyle={{ maxWidth: '300px', maxHeight: '200px', margin: '15px 0' }} />
              </div>
            )}
            {!_.get(registrationFile.value, 'content', false) && registrationFileImage && (
              <div style={{ marginTop: '10px' }}>
                <span>{registrationFileImage.name}</span>
                {registrationFileImage.url && <ImagePreview src={registrationFileImage.url} />}
                {!registrationFileImage.url && (
                  <FileImage
                    imageObject={registrationFileImage.url}
                    imageStyle={{ maxWidth: '300px', maxHeight: '200px', margin: '5px 0' }}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="newVehicleForm_container">
          <div className="newVehicleForm_subHeader">
            <FormattedMessage id="vehicle_detail_title_usage" />
          </div>
          <div className="newVehicleForm_field">
            <SelectField
              value={usage.value}
              onChange={_.partial(this.handleChangeField, usage)}
              floatingLabelText={<FormattedMessage id="vehicle_detail_title_usage" />}
              fullWidth
              errorText={!usage.touched || !usage.error ? '' : <FormattedMessage id={usage.error.type.toLowerCase()} />}
            >
              {vehicleUsageTypes.map(item => {
                return <MenuItem key={item.key} value={item.key} primaryText={<FormattedMessage id={item.label} />} />;
              })}
            </SelectField>
          </div>
        </div>

        {hasSites && (
          <div className="newVehicleForm_container">
            <div className="newVehicleForm_subHeader">
              <FormattedMessage id="vehicleForm_siteLocation" />
            </div>
            <div className="newVehicleForm_fieldWrap">
              <div className="newVehicleForm_field">
                <SlimerSelect
                  id="siteLocation"
                  multiple={false}
                  field={siteLocation}
                  allowDeselect={false}
                  onChange={this.onSiteLocationSelect}
                  options={createSitesOptions(sitesList)}
                  labelKey="vehicleForm_siteLocation"
                />
              </div>
              <div className="newVehicleForm_field">
                <SlimerSelect
                  id="parkingLocation"
                  multiple={false}
                  field={parkingLocation}
                  allowDeselect={false}
                  options={createSitesOptions(parkingList)}
                  labelKey="vehicleForm_parkingLocation"
                />
              </div>
            </div>
          </div>
        )}
        <div className="newVehicleForm_container">
          <div className="newVehicleForm_subHeader">
            <FormattedMessage id="vehicle_detail_title_box_configuration" />
          </div>
          <div className="newVehicleForm_fieldWrap">
            <div className="newVehicleForm_field">
              <SelectField
                value={usedSystems.value}
                onChange={_.partial(this.handleChangeField, usedSystems)}
                floatingLabelText={<FormattedMessage id="vehicleForm_usedSystems" />}
                fullWidth
                errorText={!usedSystems.touched || !usedSystems.error ? '' : <FormattedMessage id={usedSystems.error.type.toLowerCase()} />}
              >
                {allSystemTypes.map(system => (
                  <MenuItem key={system.value} value={system.value} primaryText={system.label} />
                ))}
              </SelectField>
            </div>
            <div className="newVehicleForm_field">
              <TextField
                floatingLabelText={<FormattedMessage id="vehicleForm_deviceSerialNumber" />}
                fullWidth
                onChange={_.partial(this.handleChangeTextField, deviceSerialNumber)}
                value={deviceSerialNumber.value}
                errorText={
                  !deviceSerialNumber.touched || !deviceSerialNumber.error ? (
                    ''
                  ) : (
                    <FormattedMessage id={deviceSerialNumber.error.type.toLowerCase()} />
                  )
                }
              />
              {this.addScrollToError(deviceSerialNumber)}
            </div>
            <div className="newVehicleForm_field">
              <DatePicker
                floatingLabelText={<FormattedMessage id="vehicleForm_deviceInstallation" />}
                onChange={_.partial(this.handleChangeTextField, deviceInstallation)}
                value={deviceInstallation.value}
                autoOk
                locale={getMaterialLocale(this.props.locale)}
                DateTimeFormat={global.Intl.DateTimeFormat}
                formatDate={this.formatDate}
                textFieldStyle={datePickerTextStyle}
              />
            </div>
            <div className={classnames('newVehicleForm_field', 'newVehicleForm_field_remove_device')}>
              <DatePicker
                floatingLabelText={<FormattedMessage id="vehicleForm_deviceRemoval" />}
                onChange={_.partial(this.handleChangeTextField, deviceRemoval)}
                value={deviceRemoval.value}
                autoOk
                locale={getMaterialLocale(this.props.locale)}
                className="removal-date"
                DateTimeFormat={global.Intl.DateTimeFormat}
                formatDate={this.formatDate}
                textFieldStyle={datePickerTextStyle}
              />
              <Close
                className={classnames('clear-icon', { disabled: deviceRemoval.value === '' })}
                onClick={e => {
                  e.preventDefault();
                  this.handleChangeTextField(deviceRemoval, e, '');
                }}
              />
            </div>
          </div>
          <div className="fieldWrap">
            <div className="newVehicleForm_field">
              <SelectField
                value={deviceWithKey.value}
                onChange={_.partial(this.handleChangeField, deviceWithKey)}
                floatingLabelText={<FormattedMessage id="device-key-specs" />}
                disabled={usedSystems.value !== VEHICLE_SYSTEM_INVERS}
                fullWidth
              >
                {vehicleKeyOptions.map(item => (
                  <MenuItem key={'kl-' + item.value} value={item.value} primaryText={<FormattedMessage id={item.labelKey} />} />
                ))}
              </SelectField>
            </div>
          </div>
        </div>
        <div className="newVehicleForm_buttonWrap">
          <EkButton customClass="newVehicleForm_button" onAction={handleSubmit(this.props.onCallback)}>
            <FormattedMessage id="contractForm_submit" />
          </EkButton>
        </div>
        <Dialog
          contentStyle={{ width: '500px' }}
          actions={[
            <FlatButton
              primary
              key="upload"
              label={<FormattedMessage id="vehicleForm_upload_button" />}
              style={{ position: 'absolute', left: '8px' }}
              onClick={this.handleUploadClick}
              className="flatButton"
            />,
            <FlatButton
              key="no"
              label={<FormattedMessage id="backUserForm_cancel_button" />}
              onClick={this.handleCloseCarImageDialog}
              className="flatButton"
            />,
            <FlatButton key="yes" label="OK" className="flatButton" onClick={this.handleChangePictureUrl} />
          ]}
          modal={false}
          open={this.state.openCarImageDialog}
          onRequestClose={this.handleCloseCarImageDialog}
        >
          <TextField
            fullWidth
            floatingLabelText={<FormattedMessage id="vehicleForm_paste_url" />}
            value={this.state.pictureUrl}
            onChange={this.handleChangePictureUrlValue}
          />
        </Dialog>
      </form>
    );
  }
}

VehicleForm.displayName = 'VehicleForm';

VehicleForm.propTypes = {
  intl: PropTypes.object,
  fields: PropTypes.object,
  onSave: PropTypes.func
};

// VehicleForm.normalizedInputs = {
//   usedSystems: normalizeKeyLess
// };

export function pictureUrl() {
  return ({ value }) => {
    return safe(() => {
      if (!value.blob) {
        return url()({ value: value.image });
      }
    });
  };
}

VehicleForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'vehicleForm',
    fields: [
      'transmissionType',
      'fuelType',
      'carBrand',
      'carModel',
      'carVersion',
      'numberOfSeats',
      'vehicleClass',
      'vehicleColor',
      'pictureData',
      'vehicleOptions[]',
      'vinNumber',
      'licencePlate',
      'registrationDate',
      'registrationFile',
      'vehicleOwner',
      'siteLocation',
      'usage',
      'parkingLocation',
      'usedSystems',
      'deviceSerialNumber',
      'deviceInstallation',
      'deviceRemoval',
      'type',
      'doorsNumber',
      'description',
      'deviceWithKey',
      'proplannerId'
    ],
    validate: createValidator({
      transmissionType: [notEmpty()],
      fuelType: [notEmpty()],
      carBrand: [notEmpty()],
      carModel: [notEmpty()],
      carVersion: [notEmpty()],
      numberOfSeats: [notEmpty()],
      vehicleClass: [notEmpty()],
      vehicleColor: [notEmpty()],
      pictureData: [notEmpty(), pictureUrl()],
      vinNumber: [notEmpty(), vinNumber()],
      licencePlate: [notEmpty()],
      registrationFile: [fileValidation()],
      vehicleOwner: [notEmpty()],
      siteLocation: [notEmpty()],
      parkingLocation: [notEmpty()],
      doorsNumber: [number()]
    }),
    initialValues: {
      type: 'PASSENGER',
      transmissionType: 'MANUAL',
      fuelType: 'DIESEL',
      doorsNumber: 5,
      numberOfSeats: 5,
      vehicleOwner: 'currentCompany',
      registrationDate: moment().toDate(),
      usedSystems: VEHICLE_SYSTEM_INVERS,
      deviceWithKey: 'true',
      deviceInstallation: moment().toDate(),
      serviceLevel: 'BASIC'
    }
  },
  undefined,
  {
    // mapDispatchToProps (will bind action creator to dispatch)
    addValue: addArrayValue
  }
)(VehicleForm);

export default connect((state, ownProps) => {
  const {
    user: { userInfo },
    brands: { list },
    companies: { currentCompany },
    vehicles: { detailVehicle, copyVehicle, registrationFileImage }
  } = state;

  let toReturn = {
    userInfo,
    companyId: currentCompany.id,
    brandList: list,
    modelList: state.models.list,
    versionList: state.versions.list,
    colorList: state.colors.list,
    categoriesList: state.categories.list,
    sitesList: state.sites.list,
    parkingList: state.parkings.list,
    detailVehicle,
    locale: localeSelector(state),
    copyVehicle,
    registrationFileImage,
    vehicleUsageTypes: vehicleUsageTypesSelector(state),
    vehicleRules: currentContractVehicleValuesSelector(state),
    bookingRules: headerContractBookingValuesSelector(state)
  };

  if (ownProps.editMode) {
    toReturn.initialValues = ownProps.datas;
  } else if (!ownProps.editMode && !_.isEmpty(ownProps.datas)) {
    toReturn.initialValues = ownProps.datas;
  }

  return toReturn;
})(VehicleForm);
